@import "../../../_core//_assets/sass/utility/variables";

.services-screen {
    .container {
        .services-section {
            height: calc(100vh - 20px);
            @media (max-height: 1054px) {
                // padding-top: 20px !important;
            }
        }
    }

    .btn {
        width: 100%;
    }

    h2 {
        font-size: rem(40px);
        color: #5F2DEB;
        margin-bottom: rem(30px);
    }

    .services-section-container {
        height: calc(100vh - 250px);

        @media (max-height: 900px) {
            // height: calc(100vh - 236px);
        }
    }

    .expert-paymentMethod {
        justify-content: space-between;
        height: 100% !important;
        min-height: 80vh;

        .services-wrapper {
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            p {
                line-height: rem(19px);
                margin: 0;
                font-weight: 500;

                span {
                    font-weight: 600;
                }
            }

            &__left {
                p {
                    &:first-child {
                        font-weight: 400;
                        margin-bottom: rem(10px);
                    }

                    &:nth-child(2) {
                        font-weight: 300;

                        span {
                            font-style: italic;
                            color: #191919;
                            font-weight: 400;
                        }
                    }

                }
            }

            &__right {
                text-align: end;

                p {
                    margin-bottom: 0.625rem;
                    font-size: rem(20px);
                    color: map-get($theme-colors , primary);

                    &.sm {
                        font-size: rem(14px);
                    }
                }

                div {
                    margin-bottom: 0.625rem;
                }
            }
        }

        .company-service-charge {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0.625rem;
            align-items: center;

            .left {
                display: flex;
                flex-direction: column;
            }
        }

        .total-service-wrapper {
            border-top: 1px solid #5F2DEB;
            padding-top: 10px;
            margin-top: 10px;

            .expert-earning {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .left {
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        .wallet {
            .button-row {
                justify-content: space-between;
                gap: 15px;

                @media (max-width: 1399px) {
                    flex-direction: column;
                    gap: 10px;
                    width: 100%;
                }

                .btn {
                    padding: rem(24px) rem(30px);
                    // min-width: 212px;
                    width: 100%;

                    @media (max-width: 1399px) {
                        width: 100%;
                    }
                }
            }
        }

        @media (max-height: 1054px) {
            // height: calc(100vh - 190px) !important;
            // gap: rem(40px);
            // justify-content: flex-start;
        }

        @media (max-height: 750px) {
            // height: auto !important;
            // gap: 0;
            // justify-content: flex-start;
        }

        @media (max-height: 630px) {
            min-height: 85vh !important;
        }
    }

    .copyright {
        margin-top: rem(30px);

        @media (max-height: 1054px) {
            margin-top: rem(10px) !important;
        }
    }

    .check-buttons {
        flex-wrap: wrap;

        .check {
            // margin-bottom: rem(30px);

            label {
                display: flex;
                gap: 15px;
                padding: rem(18px);
                border-radius: 16px;
                background-color: white;
                // do not remove this commented code with discussing with the ui engineer
                // &.selected {
                //     outline: 2px solid map-get($theme-colors, "primary");
                // }

            }

            input[type="checkbox"] {
                appearance: none;
                background-color: #ffffff;
                width: 20px;
                height: 20px;
                border: 1px solid #cccfdb;
                margin: 0;
                border-radius: 4px;
                display: grid;
                align-items: center;
                justify-content: center;
                transition: all 0.3s ease;
                position: relative;

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    // top: 2px;
                    bottom: 4px;
                    left: 6px;
                    width: 6px;
                    height: 14px;
                    // border: solid #0079bf;
                    // border-width: 0 2px 2px 0;
                    transform: rotate(225deg);
                    animation: tickAnimate 200ms forwards;

                }

                &:checked {
                    background: #5F2DEB;
                    // border-color: #5F2DEB;

                    &::before {
                        box-shadow: inset 2px 2px white;
                    }
                }

                &:checked {
                    +span {
                        color: #191919;

                        &:before {
                            content: none;
                        }
                    }
                }

                +span {
                    padding-left: 0;
                    font-size: rem(16px);
                    font-weight: 500;
                    color: #191919;
                    height: auto;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &:after {
                        content: none;
                    }
                }
            }
        }

        .amount {
            font-size: rem(20px);
            font-weight: 600;
            color: map-get($theme-colors , primary);
            text-align: right;

            span {
                font-weight: 400;
            }
        }
    }
}

.rm-top-borders {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.rm-bottom-borders {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.no-borders {
    border-radius: 0 !important;
}

.service-charges-section {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    .heading {
        margin-bottom: 0.625rem;
        font-size: 1.25rem;
        color: #5F2DEB;
    }
}

.service-per-request {
    margin-bottom: 0.65rem !important;
    .service-charges-detail {
        display: flex;
        justify-content: space-between;
    }
}

.finalAmount {
    border-top: 1px solid #5F2DEB;
    padding-top: 10px !important;
    margin-top: 10px !important;
}

.service-charges-other p{
    font-weight: 300 !important;
}

.charges {
    margin-top: 0.4rem !important;
}