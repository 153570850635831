@import '../../../_core/_assets/sass/import';

// #root {
//     overflow: hidden;
// }
.dashboard-reveiw {
    overflow: auto !important;

    @media (max-width:991px) {
        & {
            padding: 0 20px;
            padding-left: 5px;
        }

        .ml-120 {
            margin-left: 0 !important;
        }

    }

    @media (max-width:400px) {
        padding: 0 5px;

    }
}

.dashboard {
    overflow: auto;
    overflow-y: hidden;
}

.reveiws {
    // overflow-y: hidden;
    height: 100%;

    .review-row-container {
        height: 100%;
    }

    @media (max-width:991px) {
        // overflow: auto;

    }

    .reviews-div {
        height: 100%;
        padding-top: 22px;
        padding-right: 20px;
        padding-left: 0;

        @media (max-width:1400px) {
            & {
                height: auto;
            }
        }

        @media (max-width:991px) {


            padding-right: 0px !important;
            padding-left: 0 !important;

        }

        .video-preview {
            height: 350px;
            border-radius: 15px;
            border: 1px solid transparent;

            @media (max-width:991px) {
                height: 300px;
            }

            video {
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-radius: 15px;
                border: 1px solid transparent;
            }

        }

        .reveiw-main-heading {
            font-size: calc(23px + 15 * (100vw - 375px) / 1545);
            margin: 0;
            font-weight: 400;
            color: map-get($theme-colors, "primary");
            @media (max-width: 1400px) and (max-height: 699px) {
                font-size: calc(19px + 15 * (100vw - 375px) / 1545)
            }
        }

        .heading-2 {
            font-size: 1.2rem;
            margin: 0;
            font-weight: 500;
            color: map-get($theme-colors, "default");
            margin-right: 5px;
            gap: 5px;
            cursor: pointer;
        }

        .plan-text {
            font-size: 1.1rem;
            font-weight: 400;
            margin-bottom: 0;
            color: map-get($theme-colors, "black");
            align-items: center;
            display: flex;

            svg {
                margin-right: 5px;
            }

        }

        .into {
            font-size: rem(18px);
            font-weight: 500;
            color: map-get($theme-colors, "black");
            margin: 12px 0;
        }

        .viewer-container-dashboard {
            height: calc(100vh - 170px);
            border-radius: 12px;
            overflow-y: auto;
            border-bottom-right-radius: 25px;

            &::-webkit-scrollbar {
                width: 12px;
                padding-left: 12px;
            }

            &::-webkit-scrollbar-track {
                background: #e0e0e0;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #a4a4a4;
                border-radius: 10px;
                border: 3px solid #e0e0e0;
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: #8c8c8c;
            }

            .attachment-wrapper {
                display: flex;
                flex-direction: column;
                gap: 20px;
            }

            .loader {
                display: flex;
                justify-content: center;
                padding-top: 30px;

                .reviewer-loader {
                    color: #5F2DEB;
                    width: 5rem;
                    height: 5rem;
                    border-width: 5px;
                }
            }

            @media (max-height:600px) {
                height: auto;
            }

            video {
                border-radius: 12px;
            }
            .image {
                zoom: 110% !important;
                position: relative;
                border-radius: 12px;

                .slider {
                    font-size: 14px;
                    align-items: center;
                    background: #6c757d;
                    display: flex;
                    justify-content: center;
                    gap: 20px;
                    padding: 5px;
                    margin-bottom: 0;
                    border-radius: 12px 12px 0 0;

                    .svg-icon {
                        width: 20px;
                        height: 20px;
                        fill: white;
                        cursor: pointer;

                        &:hover {
                            fill: #ffffffdb;
                        }
                    }

                    .slider-number {
                        display: flex;
                        color: white;
                        align-items: center;
                        gap: 5px;
                        font-weight: 600;

                    }
                }

                @media (max-width:991px) {
                    height: 300px;
                    object-fit: contain;
                }

                img {
                    height: 100%;
                    width: 100%;
                    border: 1px solid transparent;
                }
            }
        }
    }

    .background-pink {
        padding-top: 22px;
        padding-right: 26px;
        background-color: map-get($theme-colors, "pink");
        position: relative;
        margin-top: 16px;
        height: calc(100vh - 0px);

        .display-content {
            height: 100%;

        }

        .decription-height {
            height: calc(100vh - 428px);
            overflow-y: auto;
        }


        .detail-btn {
            padding: 10px 27px;
            font-weight: 400;
        }

        .content-updated {
            justify-content: space-between;
        }

        .connect-btn-dashboard {
            padding: 15px 20px;
            font-size: 1.1rem;
            font-weight: 400;
            height: 46px;

            svg path {
                fill: map-get($theme-colors, "white");
            }

            // color: map-get($theme-colors, "white");
            &:hover svg path {
                fill: map-get($theme-colors, "primary");
            }
        }

        .profile-name {
            font-size: 1.1rem;
            margin-bottom: 0;
            font-weight: 500;
            color: map-get($theme-colors, "default");


        }

        .navTabs-reveiws {
            border-bottom: 0;
            margin: 10px 0;
            padding-top: 12px;
            padding-bottom: 12px;
            border-top: 1px solid #A8A9A8;
            border-bottom: 1px solid #A8A9A8;



            .nav-link {
                background: map-get($theme-colors, "white");
                color: map-get($theme-colors, "primary");
                border: 2px solid map-get($theme-colors, "primary");
                padding: 10px 20px !important;
                font-size: 15px;
                border-radius: 7px;
                margin-right: 12px;
                font-weight: 500;

                @media (max-width:375px) {
                    font-size: rem(18px);
                }

                @media (max-width:375px) {
                    font-size: rem(16px);
                }

                &:hover {
                    background: #ffffff;
                    color: map-get($theme-colors, "primary");

                }

                &.active {
                    background: map-get($theme-colors, "primary");
                    color: #ffffff;
                    border: 2px solid map-get($theme-colors, "primary");
                    padding: 10px 30px !important;
                    border-radius: 7px;
                    margin-right: 12px;
                    // font-size: rem(24px);
                    font-weight: 500;

                    // @media (max-width:375px) {
                    //     font-size: rem(18px);
                    // }
                }
            }
        }

        .reveiw-congratulations {
            font-size: rem(40px);
            color: map-get($theme-colors, "primary");
            font-weight: 400;
            margin: 20px 0;

            @media (max-width: 1600px) {
                font-size: rem(30px);
                margin: 10px 0;
            }

        }

        .profile-heading-deck {
            h1 {
                font-size: rem(20px);
                font-weight: 400;
                color: black;
                padding-top: 36px;
            }

            p {
                font-size: rem(16px);
                font-weight: 400;
                color: black;
            }

            span {
                font-size: rem(16px);

                color: black;
            }
        }

        .image-expert {
            img {
                cursor: pointer;
                border-radius: 50%;
                height: 50px;
                width: 50px;
                object-fit: cover;
            }

            .deck-heading {
                font-size: rem(20px);
                color: map-get($theme-colors, "primary");
            }

            .img-deck {
                width: 100px;
                height: 100px;
                object-fit: cover;
            }
        }

        .Cto {
            font-size: 0.9rem;
            font-weight: 500;
            color: map-get($theme-colors, "black");
            opacity: .5;
        }

        .border-light-purple {
            border-left: 4px solid #D1C1FF !important;
        }

        .deck-slider-container {
            // height: calc(100vh - 225px);
            height: calc(100vh - 235px);
            overflow: auto;
            &::-webkit-scrollbar {
                width: 12px;
                padding-left: 12px;
            }

            &::-webkit-scrollbar-track {
                background: #e0e0e0;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #a4a4a4;
                border-radius: 10px;
                border: 3px solid #e0e0e0;
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: #8c8c8c;
            }
            // overflow-y: auto;

            .loader {
                display: flex;
                justify-content: center;
                padding-top: 30px;

                .comments-loader {
                    color: #5F2DEB;
                    width: 5rem;
                    height: 5rem;
                    border-width: 5px;
                }
            }

            @media (max-height: 710px) {
                height: calc(100vh - 245px);
            }

            @media (max-height:500px) {
                height: auto !important;
            }

            .deck-slider {
                border-left: 4px solid map-get($theme-colors, "primary");
                padding-left: 12px;
                margin-bottom: 20px;

                .deck-slider-head {
                    display: flex;
                    align-items: center;
                    gap: 8px;





                    .deck-slider__header {
                        display: flex;
                        gap: 17px;

                        .tag {
                            background-color: map-get($theme-colors, "primary");
                            padding: 2px;
                            color: #fff;
                            border-radius: 50%;
                            font-size: rem(14px);
                            width: 20px;
                            height: 20px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        h2 {
                            padding-top: 0;
                        }
                    }
                }



                h2 {
                    padding-top: 10px;
                    font-size: rem(18px);
                    font-weight: 500;
                    color: map-get($theme-colors, "default");
                }

                .deck-messages {
                    padding-left: 13px;

                    .reply-container {
                        background: #F0EBFF;
                        padding: 12px 15px;

                        .reply {
                            cursor: pointer;
                            font-weight: 500 !important;
                            color: #2699FB;
                            font-size: rem(14px);
                            display: flex;
                            align-items: center;
                            user-select: none;

                            svg {
                                margin-right: 5px;
                            }


                        }


                    }

                    .message-image {
                        background-color: map-get($theme-colors, "profile-grey");
                        height: 30px;
                        width: 30px;
                        border-radius: 50%;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 50%;
                        }
                    }

                    .message-content {
                        background-color: map-get($theme-colors, "white");
                        padding: 15px 7px;
                        border-radius: 5px;

                        p {
                            margin: 0;
                        }

                    }

                    .content-mes {
                        .name {
                            margin-bottom: 3px;
                            font-weight: 500;
                        }

                        .date {
                            margin-bottom: 3px;
                            font-weight: 300;
                        }
                    }
                }
            }
        }
    }
}

.reply-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
}


.pin-container {
    display: flex;
    align-items: center;
    border: 1px solid #EEEEEE;
    border-radius: 8px 0 0 8px;
    // padding: 0 26px 0px 0;
    // padding: 15px;
    position: relative;
    // bottom: 32px;
    width: 100%;
    height: 70px;

    textarea {
        height: 100%;
        padding: 10px;
        border-color: #9E9E9E;
        border-radius: 5px;
        resize: none;
    }

    button {
        border: none;
        background: none;

        svg {
            width: 20px;
            height: 20px;
        }
    }
}

.pin-input {
    padding: 15px;
    font-size: rem(18px);
    border: none;
    border-radius: 5px 0 0 5px;
    width: 100%;
}

.pin-button {
    padding: 9px 15px;
    opacity: .5;
    background-color: map-get($theme-colors, "white");
    color: #fff;
    border: none;
    border-radius: 0 5px 5px 0;
    cursor: pointer;

}

.pin-button-active {
    opacity: 1;
}

// .pin-button:hover {
//     opacity: 1;
// }

.profile-reveiw-dashboard {

    .close-cursor {
        cursor: pointer;
    }

    .profile-main-heading {
        font-size: rem(40px);
        font-weight: 400;
        color: map-get($theme-colors, "primary");
    }

    p {
        font-size: rem(16px);
        font-weight: 400;
        line-height: 23px;

    }

    .description-main-heading {
        font-size: rem(20px);
        font-weight: 500;
        color: map-get($theme-colors, "primary");
    }
}

.reveiw-direction {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 10px;

    .deck-name {
        font-size: 1.1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 250px;
    }
}

.review-content {
    // height: calc(100vh - 270px);
    padding-bottom: 10px;

    .review-feedback-conatainer {
        margin-top: 34px;
        margin-bottom: 34px;
        .satisfaction-container {
            padding: rem(15px);
            background-color: #ffffff;
            border-radius: 8px;
            border: 1px solid #5F2DEB;

            .satisfaction-wrapper {
                .reviwer-info {
                    display: flex;
                    gap: 6px;
                    margin-bottom: rem(14px);

                    .icon {
                        width: rem(40px);
                        height: rem(40px);
                        border-radius: 50%;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 50%;
                        }
                    }

                    .reviwer-content {
                        gap: 3px;
                        .heading {
                            color: #040404;
                            font-size: 1rem;
                            font-weight: 600;
                            margin-bottom: 0;
                        }

                        .time {
                            color: #9D9D9D;
                            font-size: rem(14px);
                            margin-bottom: 0;
                        }
                    }
                }

                .confirmation-message {
                    font-size: rem(14px);
                    color: #132647;
                    font-weight: 500;
                    margin-bottom: 1rem;
                }

                .buttons-container {
                    display: flex;
                    gap: 12px;

                    button {
                        padding: rem(12px) rem(22px);
                        font-size: 15px;
                        border: 2px solid transparent;
                        border-radius: 7px;
                        transition: all 0.15s;
                        height: 40px;
                    }

                    .default {
                        background-color: #5F2DEB;
                        color: #ffffff;

                        &:hover {
                            color: #5F2DEB;
                            border: 2px solid #5F2DEB;
                            background-color: #ffffff;
                        }
                    }

                    .invert {
                        color: #5F2DEB;
                        border: 2px solid #5F2DEB;
                        background-color: #ffffff;

                        &:hover {
                            background-color: #5F2DEB;
                            color: #ffffff;
                            border-color: transparent;
                        }
                    }
                }
            }
        }
    }

    .review-feedback-rejected {
        padding: rem(20px);
        background-color: #ffffff;
        border-radius: 8px;
        margin-top: 34px;
        margin-bottom: 34px;

        textarea {
            color: #000000;
            font-size: rem(16px);
            border: none;
            height: rem(87px);
        }

        .button-container {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            button {
                background-color: #5F2DEB;
                color: #ffffff;
                padding: 12px 22px;
                border-radius: 7px;
                border: 2px solid transparent;
                font-size: 15px;
                transition: all 0.15s;
                height: 46px;

                &:hover {
                    color: #5F2DEB;
                    border: 2px solid #5F2DEB;
                    background-color: #ffffff;
                }
            }
        }
    }

    .review-feedback-accepted {
        margin-top: 20px;
        padding: rem(36px) rem(20px);
        background-color: #5F2DEB;
        border-radius: 8px;
        margin-top: 34px;
        margin-bottom: 34px;

        p {
            font-size: rem(16px);
            color: #ffffff;
            text-align: center;
            margin-bottom: 0;
        }
    }
}

@media (max-width:1200px) {
    .reveiw-direction {
        flex-direction: column;
        align-items: flex-start;
    }

    .reveiw-congratulations {
        font-size: rem(26px) !important;
    }
}

.check-not-allowed {
    cursor: not-allowed !important;
    pointer-events: none;
}

.reveiw-dash-tab {
    height: calc(100% - 130px);

    .tab-content {
        overflow: auto !important;
        height: 100%;

        &::-webkit-scrollbar {
            display: none;
        }

    }
}

@media (max-width:575px) {
    .container-dashboard-class {
        padding: 0;
    }

    .content-updated {
        justify-content: start;
        flex-direction: column;

        .connect-btn-dashboard {
            margin-top: 23px;
        }
    }

    .deck-slider-container {

        height: calc(100vh - 500px) !important;
        // overflow: hidden;

        .loader {
            display: flex;
            justify-content: center;
            padding-top: 30px;

            .comments-loader {
                color: #5F2DEB;
                width: 5rem;
                height: 5rem;
                border-width: 5px;
            }
        }
    }

    .reveiw-dash-tab .tab-content {
        overflow: auto !important;
        // height: calc(100vh - 456px) !important;
    }
}

#uncontrolled-tab-example-tabpane-Notes {
    height: auto;
}

#uncontrolled-tab-example-tabpane-Reviewed {
    height: auto;
}

.profile-inner-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.container-dashboard-class {
    @media (max-height:600px) {
        height: calc(100vh - 120px);
    }
}

.container-dashboard-class {
    // overflow-y: auto;
    // height: calc(100vh - 117px);
    overflow-y: hidden;
    height: 100%;

    @media (max-width:991px) {
        overflow-y: auto !important;
    }
}

@media (max-height:500px) {
    .background-pink {
        height: auto !important;
    }

    .decription-height {
        height: auto !important;
    }
}

.image-marker__marker--default {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: map-get($theme-colors, "primary") !important;
}

.image-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.image-container img.blur {
    filter: blur(10px);
    transition: filter 0.3s ease;
}

.image-container.loading img {
    opacity: 0.5;
}

.svg-icon {
    cursor: pointer;
}

.marker-class {

    .deck-marker {
        width: 23px;
        height: 23px;
        margin-left: 20px;
        background: #6120e7;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: white;
        font-size: 13px;
    }
    
    .video-marker {
        padding: 6px;
        background: #5F2DEB;
        color: white;
        border-radius: 15px;
        margin-left: 20px;
        font-size: 13px;
    }
}