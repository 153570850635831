@import "_core/_assets/sass/utility/variables";


footer {
    background: transparent linear-gradient(25deg, #E0FFF9 0%, #D5D7FE 100%) 0% 0% no-repeat padding-box;
    padding: rem(69.5px) 0 rem(120px);
    @media screen and (max-width: 991px) {
        padding: rem(40px) 0 rem(45px);
    }
    h5 {
        color: map-get($theme-colors, "primary");
        font-weight: 700;
        margin-bottom: 16px;
        font-size: rem(24px);
        margin-top: rem(12px);
    }
    ul {
        li {
            margin-bottom: 9px;
            a {
                font-weight: 500;
                line-height: 1.2;
            }
        }
        &.social-links {
            display: flex;
            li {
                &:not(:last-child) {
                    margin-right: rem(38.47px);
                }
                a {
                    color: gray;
                    font-size: rem(36px);
                    &:hover {
                        color: map-get($theme-colors, "primary");
                    }
                }
            }
        }
    }
    .custom-top-margin {
        margin-top: 130px;
        @media screen and (max-width: 767px) {
            margin-top: 0px;
        }
    }
}