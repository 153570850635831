@import "_core/_assets/sass/utility/variables";


.profile-reveiw-drawer {
    position: absolute;
    right: 0;
    top: 100px;
    height: calc(100vh - 100px);
    // width: 762px;
    width: 100%;
    max-width: 762px;
    background-color: map-get($theme-colors, "white");
    z-index: 100;
    padding: 30px;
    animation: drawerAnimation 0.15s ease;
    border-bottom-left-radius: 18px;

    &.closed {
        animation: drawerAnimationClose 0.15s ease forwards;
    }

    .profile-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;

        .title {
            font-size: rem(40px);
            color: map-get($theme-colors, "primary");
            margin-bottom: 0;
            font-weight: 500;
        }

        svg {
            cursor: pointer;
        }
    }

    .profile-data {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-block: 20px;
        border-bottom: 1px solid #CFCFCF;
        border-top: 1px solid #CFCFCF;

        .user-data {
            display: flex;
            align-items: center;
            gap: 16px;

            .image {
                width: 64px;
                height: 64px;
                border-radius: 50%;
                overflow: hidden;

                img {
                    width: 100%;
                }
            }

            .name-data {
                display: flex;
                flex-direction: column;
                gap: 2px;

                p {
                    margin-bottom: 0;
                }
                .name {
                    color: #040404;
                    font-size: rem(24px);
                    font-weight: 500;
                }

                .desiginatin {
                    color: #b1b1b1;
                    font-weight: 500;
                }
            }
        }

        .connect-btn-dashboard {
            font-size: rem(20px);
            font-weight: 400;
            padding-inline: 20px;

            svg path {
                fill: map-get($theme-colors, "white");
            }

            // color: map-get($theme-colors, "white");
            &:hover svg path {
                fill: map-get($theme-colors, "primary");
            }
        }

        @media (max-width: 450px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
        }
    }

    .profile-reveiw-description {
        margin-top: 30px;
        padding-bottom: 20px;
        border-bottom: 1px solid #CFCFCF;

        .description-main-heading {
            font-size: rem(20px);
            font-weight: 500;
            color: map-get($theme-colors, "primary");
            line-height: 24px;
            margin-bottom: 12px;
        }
        
        p {
            line-height: 23px;
        }
    }

    @media (max-width: 991px) {
        top: 70px;
        height: calc(100vh - 70px);
        width: 600px;
    }

    @media (max-width: 700px) {
        width: 100vw;
        z-index: 1000;
    }

    @media (max-width: 500px) {
        padding: 15px;
    }
}

@keyframes drawerAnimation {
    0% {
        transform: translateX(762px);
    }

    100% {
        transform: translateX(0px);
    }
}
@keyframes drawerAnimationClose {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(762px);
    }
}