$col-gap-list: 0, 10, 15, 16, 20, 24, 25, 28, 30, 40, 45, 50, 55, 60, 70, 80, 90, 100, 120, 150;
$margines-list: 5,7, 10, 15, 16, 20, 25, 28, 30, 35, 40,45, 50, 60, 65, 70, 80, 90, 100;

@each $item in $margines-list {
  .mb-#{$item} {
    margin-bottom: #{$item}px !important;
  }

  .mt-#{$item} {
    margin-top: #{$item}px !important;
  }

  .pb-#{$item} {
    padding-bottom: #{$item}px !important;
  }

  .pt-#{$item} {
    padding-top: #{$item}px !important;
  }
}

@each $item in $col-gap-list {
  .col-gap-#{$item} {
    margin-left: calc((#{$item}px/2) * -1) !important;
    margin-right: calc((#{$item}px/2) * -1) !important;
  }

  .col-gap-#{$item}>div {
    padding-left: calc(#{$item}px/2) !important;
    padding-right: calc(#{$item}px/2) !important;
  }
}

$data-gap-list: 0, 2, 3, 5, 6, 7, 8, 9, 10, 12, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 70, 80, 90, 100, 110, 120, 130;

@each $item in $data-gap-list {
  [data-grid-item-gap="#{$item}"] {
    --grid-item-gap: #{$item}px;
  }

  [data-row-gap="#{$item}"] {
    --grid-row-gap: #{$item}px;
  }
}

[data-grid-item-width="30"] {
  --grid-item-width: calc(30px - var(--grid-item-gap));
}

[data-grid-item-width="50"] {
  --grid-item-width: calc(50px - var(--grid-item-gap));
}

[data-grid-item-width="88"] {
  --grid-item-width: calc(88px - var(--grid-item-gap));
}

[data-grid-item-width="100"] {
  --grid-item-width: calc(100px - var(--grid-item-gap));
}

[data-grid-item-width="125"] {
  --grid-item-width: calc(125px - var(--grid-item-gap));
}

[data-grid-item-width="150"] {
  --grid-item-width: calc(150px - var(--grid-item-gap));
}

[data-grid-item-width="200"] {
  --grid-item-width: calc(200px - var(--grid-item-gap));
}

[data-grid-item-width="230"] {
  --grid-item-width: calc(230px - var(--grid-item-gap));
}

[data-grid-item-width="250"] {
  --grid-item-width: calc(250px - var(--grid-item-gap));
}

[data-grid-item-width="300"] {
  --grid-item-width: calc(300px - var(--grid-item-gap));
}

[data-grid-item-width="1/2"] {
  --grid-item-width: calc((100% / 2) - var(--grid-item-gap));
}

[data-grid-item-width="1/3"] {
  --grid-item-width: calc((100% / 3) - var(--grid-item-gap));
}

[data-grid-item-width="1/4"] {
  --grid-item-width: calc((100% / 4) - var(--grid-item-gap));
}

[data-grid-item-width="1/5"] {
  --grid-item-width: calc((100% / 5) - var(--grid-item-gap));
}

@media (min-width: 576px) {
  [data-grid-item-width-sm="1/2"] {
    --grid-item-width: calc((100% / 2) - var(--grid-item-gap));
  }

  [data-grid-item-width-sm="1/3"] {
    --grid-item-width: calc((100% / 3) - var(--grid-item-gap));
  }

  [data-grid-item-width-sm="1/4"] {
    --grid-item-width: calc((100% / 4) - var(--grid-item-gap));
  }

  [data-grid-item-width-sm="1/5"] {
    --grid-item-width: calc((100% / 5) - var(--grid-item-gap));
  }
}

@media (min-width: 768px) {
  [data-grid-item-width-md="1/2"] {
    --grid-item-width: calc((100% / 2) - var(--grid-item-gap));
  }

  [data-grid-item-width-md="1/3"] {
    --grid-item-width: calc((100% / 3) - var(--grid-item-gap));
  }

  [data-grid-item-width-md="1/4"] {
    --grid-item-width: calc((100% / 4) - var(--grid-item-gap));
  }

  [data-grid-item-width-md="1/5"] {
    --grid-item-width: calc((100% / 5) - var(--grid-item-gap));
  }
}

@media (min-width: 992px) {
  [data-grid-item-width-lg="1/2"] {
    --grid-item-width: calc((100% / 2) - var(--grid-item-gap));
  }

  [data-grid-item-width-lg="1/3"] {
    --grid-item-width: calc((100% / 3) - var(--grid-item-gap));
  }

  [data-grid-item-width-lg="1/4"] {
    --grid-item-width: calc((100% / 4) - var(--grid-item-gap));
  }

  [data-grid-item-width-lg="1/5"] {
    --grid-item-width: calc((100% / 5) - var(--grid-item-gap));
  }
}

img {
  max-width: 100%;
}


// Image Hover
@media screen and (min-width: 1025px) {
  .image-hover {
      position: relative;
      &:after,
      &:before {
          content: '';
          height: 0;
          width: 100%;
          position: absolute;
          background: rgba(255, 255, 255, 0.3);
          top: 0;
          bottom: 0;
          margin: auto;
          transition: 0.3s linear;
      }
      &:after {
          height: 0;
          width: 100%;
          left: 0;
          right: 0;
      }
      &:before {
          height: 100%;
          width: 0px;
          left: 0;
          right: 0;
      }
      &:hover {
          &:after,
          &:before {
              height: 100%;
              width: 100%;
              opacity: 0;
          }
      }
  }
}
// Image Hover

.grid {
  //--grid-item-gap: rem(15px);
  display: grid;
  display: -moz-grid;
  display: -ms-grid;
  grid-gap: var(--grid-item-gap);
  grid-template-columns: repeat(auto-fill, minmax(var(--grid-item-width), 1fr));
  grid-row-gap: var(--grid-row-gap);

  &>div {
    position: relative;
  }
}


.d-none-lg {
  @media screen and (max-width: 991px) {
    display: none !important;
  }
}


// aos disable on mob
@media screen and (max-width: 991px) {
  [data-aos] {
    pointer-events: auto !important;
  }

  html:not(.no-js) [data-aos^="fade"][data-aos^="fade"] {
    opacity: 1 !important;
  }

  html:not(.no-js) [data-aos="fade-up"] {
    transform: none !important;
  }

  html:not(.no-js) [data-aos] {
    transform: none !important;
  }
}


.pointer {
  cursor: pointer;
}

.abs-link {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9;
  color: transparent;
  font-size: 0;
  text-indent: -2px;
}

// sliders
.slick-arrow {
  &:before {
    font-family: unset;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' fill='%23003566' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 330 330' style='enable-background:new 0 0 330 330;' xml:space='preserve'%3E%3Cpath id='XMLID_222_' d='M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001 c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213 C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606 C255,161.018,253.42,157.202,250.606,154.389z'/%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    width: 21px;
    height: 23px;
    color: unset;
    font-size: unset;
    content: "";
    display: block;
    background-size: cover;
    margin: auto;
    opacity: 1 !important;
  }
  &.slick-prev {
    left: -50px;
    &:before {
      transform: scaleX(-1);
    }
  }
  &.slick-next {
    right: -50px;
  }
  &:hover {
    opacity: 0.75;
  }
}

.sliderWrapper {
  &.have-arrows {
    @media screen and (max-width: 1600px) {
      padding-left: 40px;
      padding-right: 40px;

      .slick-arrow {
        &.slick-prev {
          left: -45px;
        }

        &.slick-next {
          right: -45px;
        }
      }
    }

    @media screen and (max-width: 991px) {
      padding-left: 0px;
      padding-right: 0px;
      // padding-bottom: rem(50px);
    }
  }
}
.slick-slider {
  @media screen and (max-width: 1300px) {
    padding-bottom: rem(15px);
    margin-bottom: rem(15px);
  }
}
.slick-dots {
  li {
    margin: 0;

    button {
      &:before {
        font-size: 10px;
      }
    }

    &.slick-active {
      button {
        &:before {
          color: map-get($theme-colors, "primary");
          opacity: 1;
        }
      }
    }
  }
}


// sliders

// breadcrumb
.breadcrumb {
  background-color: transparent;
  padding: 0;
  font-size: rem(20px);

  @media screen and (max-width: 1600px) {
    font-size: rem(18px);
  }
}

.breadcrumb-item {
  position: relative;
  padding-right: 18px;
  margin-bottom: 3px;

  &.active {
    color: #023e8a;
  }

  &::before {
    content: "" !important;
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='6.697' height='11.324' viewBox='0 0 6.697 11.324'%3E%3Cpath id='Path_1073' data-name='Path 1073' d='M18203.932,34.111l-.5-.5-5.123-5.208,1-.982,4.627,4.7,4.705-4.709.992.99Z' transform='translate(-27.414 18209.637) rotate(-90)' fill='%23545454'/%3E%3C/svg%3E%0A");
    height: 11px;
    width: 7px;
    background-repeat: no-repeat;
    top: 50%;
    position: absolute;
    right: 0px;
    transform: translateY(-50%);
  }

  &:last-child {
    &:before {
      display: none;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}



/*
  Apple in their infinite wisdom decided to center align date in the input field.
  This is a hack to get around that. 
*/
input::-webkit-date-and-time-value {
  text-align: left;
}


