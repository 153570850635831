.tracking-div {
    position: relative; 
    height: auto;
    width: 100%;
}

.custom-marker {
    width: 25px;
    height: 25px;
    background: #5F2DEB !important;
    text-align: center;
    border-radius: 50%; 
    cursor: pointer;
    color: white;
    transform: translate(-50%, -50%);

    .displaying-index {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.disable {
    pointer-events: none !important;
}