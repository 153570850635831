@import "_core/_assets/sass/utility/variables";

.status {
    background-color: map-get($theme-colors, "white");
    border-radius: rem(10px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: rem(32px);
    flex: 1;
    border: 1px solid map-get($theme-colors, "grey-border-light");
    height: rem(70px);
    padding: rem(23px);

    .title {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        gap: rem(8px);
        color: map-get($theme-colors, "default");
        font-size: rem(20px);
        font-weight: 400;

        @media (max-width: 1500px) {
            font-size: rem(18px);

            svg {
                width: 28px;
            }
        }
        @media (max-width: 1400px) {
            font-size: rem(16px);

            svg {
                width: 24px;
            }
        }
    }

    .count {
        margin-bottom: 0;
        font-size: rem(34px);
        color: map-get($theme-colors, "primary");

        @media (max-width: 1500px) {
            font-size: rem(28px);
        }
        @media (max-width: 1400px) {
            font-size: rem(24px);
        }
    }

    @media (min-width: 500px) and (max-width: 900px) {
        padding: rem(16px);
      }
}