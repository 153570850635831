@import "_core/_assets/sass/utility/variables";

.experts {
    text-align: center;
    .subTitle {
        max-width: 550px;
        margin: auto;
        margin-bottom: rem(90px);
        @media screen and (max-width: 1600px) {
            margin-bottom: rem(70px);
        }
        @media screen and (max-width: 991px) {
            margin-bottom: rem(45px);
        }
    }
    .experts_listing {
        overflow: hidden;
        max-width: 218px;
        min-width: 218px;
        margin: auto;
        margin-bottom: 15px;
        .experts_listing--img {
            border: 3px solid map-get($theme-colors, "primary");
            border-radius: rem(28px);
            margin-bottom: rem(15px);
            overflow: hidden;
        }
        .experts_listing--desc {
            h5 {
                font-size: rem(16px);
                font-weight: 700;
                margin-bottom: 0;
            }
            .rating {
                font-size: rem(16px);
                font-weight: 700;
                display: flex;
                align-items: center;
                line-height: normal;
            }
            p {
                text-align: left;
                font-size: rem(14px);
                font-weight: 600;
                margin-top: 9px;
                margin-bottom: 0;
            }
            svg {
                margin-right: 5px;
            }
        }
    }
    .slick-slide {
        text-align: center;
    }
    .many-more {
        font-size: rem(28px);
        font-weight: 500;
        color: map-get($theme-colors, "primary");
        margin-top: rem(30px);
        display: inline-block;
        @media screen and (max-width: 991px) {
            font-size: rem(22px);
            margin-top: rem(25px);
        }
        &:hover {
            opacity: .85; 
        }
    }
}

