@import '../../../_core/_assets/sass/import';

.dashboard {
    // height: 100vh;
    overflow: hidden;
    height: 100%;

    .dashboard-header {
        background-color: map-get($theme-colors, "white");
        padding: 0px 30px;
        height: 85px; //usama-change
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 575px) {
            padding: 15px;
        }
    }

    .dashboard-layout {
        padding: 0;
        display: flex;
        align-items: center;
        gap: 0;
        height: calc(100vh - 100px);
        height: 99vh;
        position: relative;
        @media (max-height: 700px) {
            height: 96vh;
        }
        @media (max-width: 600px) {
            margin: 15px;
        }

        .hamburger-menu {
            position: absolute;
            width: 40px;
            height: 40px;
            z-index: 10;
            cursor: pointer;
            display: none;
            transition: all 0.15s;

            &.hide {
                left: 70px;
                top: 20px;
            }

            &.show {
                // left: 324px;
                left: 280px;
                top: 20px;
            }

            @media (max-width: 1200px) {
                display: block;
            }

            @media (max-width: 600px) {
                display: none;
            }
        }

        .dashboard-content {
            border-radius: 16px;
            // background-color: rgba(255, 255, 255, 0.8);
            height: 100%;
            // flex: 1;
        }

        @media (max-width: 991px) {
            height: calc(100vh - 70px);
        }
    }
}

.ml-120 {
    margin-left: 120px !important;

    @media (max-width: 1200px) {
        margin-left: 105px !important;
    }

    @media (max-width: 600px) {
        margin: 0px !important;
    }

    // @media (max-width: 400px) {
    //     margin-left: 0px !important;
    // }
}