.attachment-preview {
    position: relative;

    .bin {
        z-index: 11111;
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 1;
        background: white;
        padding: 3px;
        font-size: 25px;
        border-radius: 2px;
        cursor: pointer;
    }

    .preview-document-container {
        width: 100%;
        height: 100%;
        background: black;
        border-radius: 15px;
        position: relative;
        background-color: black !important;

        .preview-document {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 20px;
            font-size: 18px;
            font-weight: 500;
            position: relative;
            background-color: black !important;

            .document-class {
                --scale-factor: 1;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                background-color: black !important;

                .left-arrow {
                    z-index: 11111111111111111111111111111111111;
                    width: 50px;
                    height: 50px;
                    position: absolute;
                    left: 5px;
                    top: 20vh;
                    color: #ffffffbf;
                    cursor: pointer;
                }

                .right-arrow {
                    position: absolute;
                    z-index: 11111111111111111111111;
                    right: 1;
                    width: 50px;
                    height: 50px;
                    right: 5px;
                    top: 20vh;
                    color: #ffffffbf;
                    cursor: pointer;
                }

                .page-class {
                    --scale-factor: 1;
                    position: relative;
                    min-width: min-content;
                    min-height: min-content;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    width: 100%;
                    height: 100%;
                    background-color: black !important;
                    color: white !important;

                    .react-pdf__Page__canvas {
                        display: block;
                        user-select: none;
                        width: 100% !important;
                        height: 100% !important;
                        object-fit: contain;
                        background-color: black !important;
                    }
                }
            }
        }
    }
}

#header-bar {
    display: none;
}

.disabled {
    cursor: not-allowed !important;
    opacity: 0.3;
    user-select: none;
}