html {
  font-size: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  min-height: 100%;

  @media screen and (min-width: 992px) and (max-width: 1600px) {
    font-size: 96%;
  }
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-size: rem(16px);
  font-family: $primary-font;
  color: map-get($theme-colors, "default");
  line-height: 1.06;
  margin: 0;
  overflow-x: hidden;
  background: transparent linear-gradient(119deg, #D4FFF6 0%, #C1BCFF 100%) 0% 0% no-repeat padding-box;
  width: 100%;
  scroll-behavior: smooth;
  zoom: 90%;
  &.no-scroll {
    overflow: hidden;
    position: fixed;
  }
  &.modal-open {
    position: fixed;
    overflow: hidden;
  }
  // @media screen and (min-width: 992px) and (max-width: 1600px) {
  //   zoom: .98;
  // }
}

body::-webkit-scrollbar {
  width: 14px;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

body::-webkit-scrollbar-thumb {
background-color: map-get($theme-colors, "primary");
outline: 1px solid slategrey;
}

@media screen and (max-width: 991px) {
  html {
    font-size: 80%;
  }
}

input {
  &::-ms-reveal {
    display: none;
  }
}