fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
input,
select,
textarea {
  appearance: none;
  background-clip: border-box;
  margin: 0;
  outline: 0;
  text-align: left;
  vertical-align: top;
  width: 100%;
}
input[type="checkbox"],
input[type="radio"] {
  width: auto;
  height: auto;
}
input[type="checkbox"] {
  appearance: checkbox;
}
input[type="radio"] {
  appearance: radio;
}
textarea,
select[size],
select[multiple] {
  height: auto;
}

.form-group {
  margin-bottom: rem(18px);
  position: relative;
  &.extra-mb {
    margin-bottom: rem(30px);
    @media (max-height: 1054px) {
      margin-bottom: rem(10px);
    }
    @media screen and (max-width: 1600px) {
      // margin-bottom: rem(25px);
      margin-bottom: rem(10px);
    }
  }
  @media (max-height: 1054px) {
    margin-bottom: rem(10px);
  }
  @media screen and (max-width: 1600px) {
    margin-bottom: rem(10px);
  }
}

.control-label {
  font-size: rem(16px);
  font-weight: 400;
  margin-bottom: rem(15px);
  line-height: normal;
  padding-left: 3px;
  &.big {
    font-size: rem(44px);
    padding-left: 0px;
    @media screen and (max-width: 1600px) {
      font-size: rem(32px);
    }
    @media screen and (max-width: 1200px) {
      font-size: rem(26px);
      // margin-bottom: 0px;

    }
    @media screen and (max-width: 991px) {
      font-size: rem(22px);
    }
    @media screen and (max-width: 575px) {
      font-size: rem(20px);
    }
  } 
  @media (max-height: 1054px) {
    margin-bottom: rem(10px);
  }
}

.form-control {
  position: relative;
  height: rem(66px);
  font-size: rem(16px);
  background-color: white;
  outline: none;
  padding: 0px 12px;
  outline: none;
  border: 1px solid map-get($theme-colors, "grey-border");
  border-radius: 8px;
  -webkit-appearance: none;
  font-weight: 500;
  color: map-get($theme-colors, "default");
  @media screen and (max-width: 1600px) {
    height: rem(50px);
  }
  &.sm {
    height: rem(56px);
    @media screen and (max-width: 1600px) {
      height: rem(43px);
    }
  }
  &.only-bottom-b {
    border-top: 0; 
    border-left:  0;
    border-right:  0;
    border-radius: 0;
    font-size: rem(20px);
    padding-left: 0;
    font-weight: 400;
    height: auto;
    @media screen and (min-width: 1601px) {
      padding-bottom: rem(15px);
    }
    @media screen and (max-width: 1600px) {
      font-size: rem(18px);
      padding-bottom: rem(10px);
    }
    @media screen and (max-width: 1200px) {
      font-size: rem(16px);
    }
  }
  &.active {
    outline: none;
    box-shadow: none;
    border-radius: 0;
  }
  &::placeholder {
    color: map-get($theme-colors, "grey-light");
  }
  &:focus {
    outline: none;
    box-shadow: none;
    border-color: map-get($theme-colors, "primary");
  }
  &:read-only {
    // background: #fff;
  }
  &:disabled {
    background: transparent;
  }
  &.error {
    border-color: #ff0000;
  }
}
.text-area{
  height: rem(145px);
  padding: 12px;
}

.forgetPW,.paymentMethod {
  min-height: rem(756px);
  // @media screen and (max-width: 1600px) {
  //   min-height: rem(550px);
  // }
  @media (max-height: 1054px) {
    min-height: fit-content;
  }
}

form{
  height: inherit;
}