@import "_core/_assets/sass/utility/variables";

.dashboard-home {
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(29px);
    border-radius: rem(16px);
    // height: calc(100% - 60px);
    height: calc(100% - 32px);
    margin: 30px;
    margin-left: 0;
    // margin-top: 39px;
    margin-top: 27px;
    margin-bottom: 10px;
    overflow: hidden !important;


    &__content {
        padding: 1rem;
        height: calc(100% - 78px);
        overflow-y: scroll;
        // overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        .status-container {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            margin-bottom: rem(18px);
            gap: 10px;

            @media (max-width: 900px) {
                grid-template-columns: 1fr 1fr;
            }

            @media (max-width: 600px) {
                grid-template-columns: 1fr;
            }
        }

        .filter-container {
            display: flex;
            align-items: center;
            gap: 30px;
            margin-bottom: 1.1rem;

            .filter-left {
                flex: 1.5;
                .form-group {
                    position: relative;
                    margin-bottom: 0;

                    svg {
                        position: absolute;
                        right: rem(20px);
                        top: 50%;
                        transform: translate(0, -50%);
                        width: rem(23px);
                        height: rem(23px);
                    }
                }
                &.short-input {
                    flex: 1;
                }
            }
            .filter-right {
                flex: 2;
                display: flex;
                align-items: center;
                gap: rem(15px);

                .select-multiple-filters {
                    flex-wrap: wrap;
                    row-gap: 10px;
                    
                    .radio , .checkbox {
                        margin-bottom: 8px;
                        margin-bottom: 0;

                        &:not(:last-child) {
                            margin-right: rem(8px);
                        }
                        input[type="radio"] , input[type="checkbox"] {
                          appearance: none;
                            &:checked {
                                +span {
                                    color: #ffffff;
                                    background-color: map-get($theme-colors, "primary");
                                    border-color: transparent;
                                    &:before {
                                        content: none;
                                    }
                                }
                            }
                            +span {
                                padding-left: 0;
                                font-size: rem(20px);
                                font-weight: 300;
                                padding: 8px rem(19.5px);
                                border-radius: 12px;
                                height: rem(55px);
                                width: auto;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: map-get($theme-colors, "primary");
                                background-color: map-get($theme-colors, "white");
                                border: 1px solid map-get($theme-colors, "primary");

                                &:after {
                                    content: none;
                                }
                                @media screen and (max-width: 1600px) {
                                    height: 40px;
                                padding: 8px rem(15px);
                                font-size: rem(18px); 
                                }
                                @media screen and (max-width: 1200px) {
                                padding: 7px rem(15px);
                                  font-size: rem(16px);
                                }
                                @media screen and (max-width: 991px) {
                                  padding: 6px rem(12.5px);
                                    font-size: rem(14px);
                                  }
                            }
                        }
                    }
                }
            }

            // @media (max-width: 1500px) {
            //     flex-direction: column;
            //     align-items: flex-start;

            //     .filter-left, .filter-right {
            //         width: 100%;
            //     }
            // }
            @media (max-width: 1347px) {
                .filter-left {
                    flex: 1;
                }
            }

            @media (min-width: 500px) and (max-width: 900px) {
                gap: 15px !important;
              }
        }

        .pitch-scrollbar {

            .dashboard-list {
                display: flex;
                flex-direction: column;
                gap: rem(18px);
    
            }
        }
    }

    .header-container {
        .header {
            .submission-btn {
                min-width: rem(252px);
                min-height: rem(48px);
                padding: rem(12px) rem(31px);
                font-weight: 400;
            }
        }
    }

    @media (max-height: 700px) {
        margin-top: 18px;
        height: calc(100% - 18px);
    }
    @media (max-width: 600px) {
        margin-right: 0;
    }
}