@import "functions";


:root {
  --nav-item-offset: 10vw;
  --nav-item-hover-offset: 2%;
  --easing: cubic-bezier(0.8, 0, 0.2, 1);
  --duration: 0.6s;
  --overlay-bg: #000 !important;
  --transition-duration: 0.4s !important;
  --toggleColor: var(--primary);
}

$theme-colors: (
  "primary": #5F2DEB,
  "default": #191919,
  "white": #ffffff,
  "grey-light": #A8A8A8,
  "grey-border": #A8A9A8,
  "grey-border-light": #D6D6D6,
  "grey-border-very-light": #00000029,
  "yellow": #dcb51f,
  "yellow-secondary": #EFCB40,
  "black":black,
  "pink":#F8F5FF,
  "profile-grey":#D1D1D1,
   "sky-blue": #2699FB,
);

// Fonts

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
$primary-font: "Montserrat", sans-serif;