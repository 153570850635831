// @import '../../../../../_core/_assets/sass/import';
@import "_core/_assets/sass/utility/variables";

.payment-list-item {
    padding: rem(18px);
    background-color: map-get($theme-colors, "white" );
    box-shadow: 0px 5px 9px #00000029;
    border: 1px solid #F5F5F5;
    border-radius: rem(10px);
    display: flex;
    // justify-content: space-between;
    row-gap: rem(20px);
    // height: 123px;
    height: 130px;

    &__left {
        display: flex;
        justify-content: space-between;

        .item-info {
            display: flex;
            gap: rem(18px);
            border-right: 1px solid map-get($theme-colors, grey-border-very-light);
            padding-right: 22px;
            width: 380px;
            min-width: 310px;
    
            .item-img {
                width: 80px;
                height: 80px;
                min-width: 80px;
                min-height: 80px;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: rem(8px);
                }
            }
    
            .item-content {
                display: flex;
                flex-direction: column;
    
                p {
                    margin-bottom: 0;
                    color: map-get($theme-colors, "default");
                }
    
                .name {
                    font-size: rem(20px);
                }

                .link {
                    width: 210px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
    
                .link, .review-by {
                    font-size: rem(16px);
                }
    
                .review-by {
                    margin-top: rem(20px);
                    font-style: italic;
                }

                @media (max-width: 1430px) {
                    .name {
                        // font-size: rem(19px);
                    }
                    .link, .review-by {
                        font-size: rem(15px);
                    }
                    .review-by {
                        margin-top: rem(9px);
                    }
                }
            }
            // @media (max-width: 1580px) {
            //     width: 333px;
            // }

            // @media (max-width: 1430px) {
            //     width: 280px;
            // }

            @media (max-width: 1430px) {
                width: 320px;
            }

            @media (max-width: 700px) {
                border-right: none;
            }
        }

        .target-experts {
            display: flex;
            flex-direction: column;
            border-right: 1px solid map-get($theme-colors, grey-border-very-light);
            padding-right: 22px;
            padding-left: 22px;
            // width: 540px;
            width: 750px;
            height: 100%;
            overflow: auto;
            &::-webkit-scrollbar {
                width: 5px;
            }
               
            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            }
            
            &::-webkit-scrollbar-thumb {
                background-color: rgba(0, 0, 0, 0.4);
            }

            .t-name {
                @media (max-width: 1681px) {
                    margin-bottom: 10px !important;
                }
            }
    
            .name {
                margin-bottom: 0;
                font-size: rem(16px);
                font-weight: 500;
                position: sticky;
                top: 0;
                background-color: #fff;
                z-index: 10;
                margin-top: 5px;
            }
    
            .label-container {
                display: flex;
                align-items: center;
                gap: rem(11px);
                margin-top: rem(31px);
    
                .select-multiple-buttons {
                    .checkbox {
                        label {
                            span {
                                font-size: 14px;
                                border: 1px solid #A8A9A840;
                                padding: 8px 20px;
                                border-radius: 20px;
                                @media (max-width: 1837px) {
                                    // font-size: rem(16px);
                                    // padding: rem(8px) rem(10px);
                                }
                                @media (max-width: 1655px) {
                                    // font-size: rem(15px);
                                }
                                @media (max-width: 1521px) {
                                    // font-size: rem(14px);
                                }
                                @media (max-width: 1405px) {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }

            @media (max-width: 1880px) {
                width: 700px;
            }

            @media (max-width: 1820px) {
                width: 650px;
            }

            @media (max-width: 1780px) {
                width: 600px;
            }

            @media (max-width: 1700px) {
                width: 550px;
            }

            @media (max-width: 1650px) {
                width: 500px;
            }

            @media (max-width: 1600px) {
                width: 450px;
            }

            @media (max-width: 1550px) {
                width: 400px;
            }

            @media (max-width: 1480px) {
                width: 350px;
            }

            @media (max-width: 1380px) {
                width: 300px;
            }

            @media (max-width: 1330px) {
                width: 270px;
            }
        }

        @media (max-width: 700px) {
            flex-direction: column;
            gap: 10px;
        }
    }

    &__right {
        display: flex;
        // flex: 1;
        gap: 5px;

        .first-half {
            display: flex;
            // justify-content: space-between;
            // flex: 2;
            gap: 5px;

            @media (max-width: 1840px) {
                justify-content: space-between;
                flex: 1;
                gap: 0;
            }
        }

        .second-half {
            display: flex;
            // justify-content: space-around;
            // flex: 1.5;
            gap: 5px;

            @media (max-width: 1840px) {
                justify-content: space-between;
                flex: 1;
                gap: 0;
            }
        }

        .experts-container, .status-container, .scale-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 5px;

            .name {
                margin-bottom: 0;
                font-size: rem(16px);
                font-weight: 500;

                @media (max-width: 1683) {
                    font-size: 13px;
                }
            }
    
            .expert {
                display: flex;
                align-items: flex-end;
                gap: rem(12px);
                margin-bottom: rem(20px);
                text-transform: capitalize;
    
                .expert-icon {
                    width: 22px;
                    padding: 1px;
                    border: 1px solid #CFCFCF;
                    border-radius: 50%;
                    overflow: hidden;
                    
                    img {
                        width: 100%;
                        border-radius: 50%;
                    }
                }

                .cursor {
                    cursor: pointer;
                }
    
                p {
                    margin-bottom: 0;
                    color: map-get($theme-colors, "primary");
                    font-size: rem(18px);
    
                    // @media (max-width: 1655px) {
                    //     font-size: rem(16px);
                    // }
                    // @media (max-width: 1521px) {
                    //     font-size: rem(15px);
                    // }
                }
            }

            @media (max-width: 520px) {
                width: 100%;
            }
        }

        .experts-container {
            // width: 190px;
            width: 200px;
            height: 100%;
            border-right: 1px solid map-get($theme-colors, grey-border-very-light);
            padding-right: 22px;
            padding-left: 12px;
            margin-top: 5px;

            @media (max-width: 1500px) {
                width: 170px;
            }

            // @media (max-width: 1380px) {
            //     width: 181px;
            //     padding-left: 16px;
            // }
            // @media (max-width: 1340px) {
            //     width: 160px;
            //     padding-left: 14px;
            // }
        }

        .status-container {
            width: 130px;
            height: 100%;
            border-right: 1px solid map-get($theme-colors, grey-border-very-light);
            padding-right: 22px;
            padding-left: 12px;
            margin-top: 5px;

            // @media (max-width: 1380px) {
            //     width: 100px;
            //     padding-left: 16px;
            // }

            @media (max-width: 520px) {
                border-right: none;
            }
        }

        .payment-container {
            border-right: none !important;
            width: 130px;
        }
    
        .button-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 198px;
            align-items: flex-end;
            button {
                max-height: 60px;
                font-weight: 400;
                @media (max-width: 1837px) {
                    padding-inline: rem(30px);
                }
                @media screen and (max-width: 1471px) {
                    flex: 1;
                }
                @media (max-width: 1655px) {
                    padding-inline: rem(20px);
                }
            }

            @media (max-width: 520px) {
                width: 100%;
            }
        }

        @media (max-width: 1840px) {
            display: flex;
            flex: 1;
            gap: 0;
        }

        @media (max-width: 520px) {
            flex-direction: column;
        }
    }
}