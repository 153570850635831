@import '../../../_core/_assets/sass/import';

.dashboard {
    height: 100vh;

    .dashboard-header {
        background-color: map-get($theme-colors, "white");
        padding: 30px 50px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .dashboard-layout {
        padding: 30px;
        display: flex;
        align-items: center;
        gap: 30px;
        height: calc(100% - 100px);

        .dashboard-content {
            border-radius: 16px;
            // background-color: rgba(255, 255, 255, 0.8);
            flex: 1;
            height: 100%;
        }
        .dashboard-reveiw {
            height: 100vh !important;   
        }
        &.layout-full {
            height: calc(100% - 86px);
        }
    }
}

.zoom-0 {
    // zoom: 0;
    zoom: 90%;
}