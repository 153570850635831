@import "../../../_core//_assets/sass/utility/variables";

.deck-info {
    .button-row {
        justify-content: space-between;
        @media (max-width: 1399px) {
            flex-direction: column;
            gap: 10px;
            width: 100%;
        }
        
        .btn {
            padding: rem(24px) rem(30px);
            @media (max-width: 1399px) {
                width: 100%;
            }
        }
    }

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 991px) {
            flex-direction: column;
            align-items: flex-start;
        }
        h2 {
            font-size: rem(40px);
            color: #5F2DEB;
            margin-bottom: rem(30px);
            @media (max-width: 1399px) {
                font-size: rem(35px);
            }
            @media (max-width: 991px) {
                margin-bottom: unset;
            }
        }

        p {
            @media (max-width: 1399px) {
                font-size: rem(15px);
            }
            svg {
                margin-right: 5px;
            }
        }
    }

    .uploader {
        border-radius: 16px;
        border: 2px solid #5F2DEB;
        border-style: dashed;
        width: 100%;
        height: 403px;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 10px;

        h3 {
            color: #5F2DEB;
            margin-top: rem(15px);
            margin-bottom: rem(18px);
        }
        @media screen and (max-width: 1600px) {
            height: 250px;
        }
        @media screen and (max-width: 400px) {
            svg {
                width: 50px;
                height: 50px;
            }
            h3 {
                font-size: 18px;
            }
            .upload-btn-wrapper {
                button {
                    padding-inline: 25px;
                    font-size: 18px;
                }
            }
        }
    }

    .img-preview-wrapper{
        height: 778px;
        overflow: auto;
        margin-bottom: 10px;
        .img-preview {
            margin-block-end: 10px;
            h4{
                font-size: rem(18px);
                color: #040404;
                font-weight: 400
            }
            img {
                width: 100%;
                max-height: 406px;
                border-radius: 12px;
                object-fit: cover;
            }
        }
    }
    .video-preview-wrapper{
        .video-preview{
            video{
                width: 100%;
                border-radius: 12px;
            }
        }
    }

    .paymentMethod {
        height: rem(856px);

        h3 {
            margin-bottom: rem(20px);
        }
    }

    .copyright {
        margin-top: rem(30px);
    }

    .form-wrapper {
        height: 100%;

        .form-inner-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }
    }

    .form-control {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;

        // overflow: hidden;
        &.industry {
            height: auto;
            max-height: rem(100px);
            padding-top: 5px;
            padding-bottom: 5px;
            overflow-y: auto;
            &::-webkit-scrollbar {
                display: none;
            }
        }

        .industry-label {
            background-color: #c3c3fe4d;
            border-radius: 20px;
            box-shadow: 0px 1px 6px #00000008;
            padding: 8px 12px;
            color: #5F2DEB;
        }
    }
}