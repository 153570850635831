@import "../../../_core//_assets/sass/utility/variables";

.deck-info {
	.container {
		section {
			@media (max-width: 1600px) {
				padding-top: 20px;
			}
			@media (max-height: 1054px) {
				padding-top: 20px;
			}
		}
	}
	.button-row {
		justify-content: space-between;
		gap: 10px;

		@media (max-width: 768px) {
			flex-direction: column;
			gap: 10px;
			width: 100%;
		}

		.btn {
			// width: 100% !important;
			flex: 1;
			padding: rem(24px) rem(30px);

			@media (max-width: 768px) {
				width: 100%;
				flex: unset;
			}
		}
	}

	.wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media (max-width: 991px) {
			flex-direction: column;
			align-items: flex-start;
		}

		h2 {
			font-size: rem(40px);
			color: #5F2DEB;
			margin-bottom: rem(30px);

			@media (max-width: 1399px) {
				font-size: rem(35px);
			}

			@media (max-width: 991px) {
				margin-bottom: unset;
			}
		}

		p {
			display: flex;
			align-items: center;
			gap: 4px;
			font-size: rem(20px);
			color: #040404;
			svg {
				width: 19px;
				height: 19px;
			}
			@media (max-width: 1399px) {
				font-size: rem(15px);
			}

			svg {
				margin-right: 5px;
			}
		}


	}

	.loader-background {
		height: 400px;
		background: white;

		.loader-wrapper {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
    		gap: 20px;

			.loading-text {
				font-size: 18px;
			}

			.deck-loader {
				width: 70px;
				height: 70px;
				border-width: 6px;
				color: #5F2DEB;
			}
		}
	}

	.file-drag-drop {
		width: 100%;
		cursor: pointer;
		outline: none !important;
		border: none !important;

		.uploader {
			border: 4px dashed #5F2DEB;
			cursor: pointer;
			width: 100%;
			height: 403px;
			background-color: #fff;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			margin-bottom: 10px;

			h3 {
				color: #5F2DEB;
				margin-top: rem(15px);
				margin-bottom: rem(18px);
			}

			@media screen and (max-width: 1600px) {
				height: 400px;
				gap: 25px;
			}

			@media screen and (max-width: 400px) {
				svg {
					width: 50px;
					height: 50px;
				}

				h3 {
					font-size: 18px;
				}

				.upload-btn-wrapper {
					button {
						padding-inline: 25px;
						font-size: 18px;
						cursor: pointer;

						&:hover {
							background: #5f2debd4;
							border: 1px solid #5f2debd4
						}
					}
				}
			}
		}
	}

	.img-preview-wrapper {
		height: 778px;
		overflow: auto;
		margin-bottom: 10px;

		.img-preview {
			margin-block-end: 10px;

			h4 {
				font-size: rem(18px);
				color: #040404;
				font-weight: 400
			}

			img {
				width: 100%;
				max-height: 406px;
				border-radius: 12px;
				object-fit: cover;
			}
		}
	}

	.video-preview-wrapper {
		.video-preview {
			video {
				width: 100%;
				border-radius: 12px;
			}
		}
	}

	.paymentMethod {
		height: unset !important;

		h3 {
			margin-bottom: rem(20px);
			@media (max-height: 1054px) {
				margin-bottom: rem(10px) !important;
			}
		}
		@media (max-height: 1054px) {
			padding: rem(13px) !important;
		}
	}

	.copyright {
		margin-top: rem(30px);
		@media (max-height: 1054px) {
            margin-top: rem(10px) !important;
        }
	}

	.form-wrapper {
		height: 100%;

		.form-inner-wrapper {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;
		}
	}

	.text-area-form {
		padding: 10px;
		// min-height: calc(100vh - 650px);
		// height: calc(100vh - 450px);
		// max-height: calc(100vh - 530px);
	}

	.form-control {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 8px;

		// overflow: hidden;
		&.industry {
			height: auto;
			max-height: rem(100px);
			padding-top: 5px;
			padding-bottom: 5px;
			overflow-y: auto;
		}

		.industry-label {
			background-color: #c3c3fe4d;
			border-radius: 20px;
			box-shadow: 0px 1px 6px #00000008;
			padding: 8px 12px;
			color: #5F2DEB;
		}
	}
}

.pitch-file-viewer {
	height: 400px;
}

.deck-viewer {
	display: flex;
	flex-direction: column;
	height: 400px !important;
	.deck-header {
		display: flex;
    justify-content: end;
    background: #444444;
    padding: 7px;
	.trash-bin {
		color: #ffffffcf;
    cursor: pointer;
    width: 18px;
    height: 18px;
	}
	}

	.doc-viewer {
		height: 100% !important;

	}
}