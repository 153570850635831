// @import '../../../../../_core/_assets/sass/import';
@import "_core/_assets/sass/utility/variables";

.founder-list-item {
    padding: rem(13px);
    background-color: map-get($theme-colors, "white" );
    box-shadow: 0px 5px 9px #00000029;
    border: 1px solid #F5F5F5;
    border-radius: rem(10px);
    display: flex;
    // justify-content: space-between;
    row-gap: rem(20px);
    height: 123px;
    

    &__left {
        display: flex;
        justify-content: space-between;

        .item-info {
            display: flex;
            gap: rem(18px);
            border-right: 1px solid map-get($theme-colors, grey-border-very-light);
            padding-right: 22px;
            min-width: 310px;
        
    
            .item-img {
                width: 80px;
                height: 80px;
                min-width: 80px;
                min-height: 80px;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: rem(8px);
                }
            }
    
            .item-content {
                display: flex;
                flex-direction: column;
                word-break: break-all;
    
                p {
                    margin-bottom: 0;
                    color: map-get($theme-colors, "default");
                }
    
                .name {
                    font-size: rem(20px);
                    @media (max-width: 1750px) {
                        font-size: rem(18px);
                    }
                    @media (max-width: 1521px) {
                        font-size: rem(18px);
                    }
                }

                .link {
                    width: 210px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
    
                .link, .review-by {
                    font-size: rem(16px);
                    @media (max-width: 1750px) {
                        font-size: rem(15px);
                    }
                    @media (max-width: 1521px) {
                        font-size: rem(14px);
                    }
                }
    
                .review-by {
                    margin-top: rem(15px);
                    font-style: italic;
                }

                &.expert-item-content {
                    @media (max-width: 1350px) {
                        .name {
                            font-size: rem(18px);
                        }
                        .link, .review-by {
                            font-size: rem(13px);
                        }
                        .review-by {
                            margin-top: rem(8px);
                        }
                    }
                }
            }

            // @media (max-width: 1750px) {
            //     width: 330px;
            // }

            // @media (max-width: 1390px) {
            //     width: 310px;
            // }
        }

        .target-experts {
            display: flex;
            flex-direction: column;
            margin-top: 8px;
            border-right: 1px solid map-get($theme-colors, grey-border-very-light);
            padding-right: 22px;
            padding-left: 22px;
            // width: 640px;
            width: 900px;
            position: relative;
            height: 100%;
            overflow: auto;
            &::-webkit-scrollbar {
                width: 5px;
            }
               
            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            }
            
            &::-webkit-scrollbar-thumb {
                background-color: rgba(0, 0, 0, 0.4);
            }

            .t-name {
                @media (max-width: 1681px) {
                    margin-bottom: 10px !important;
                }
            }
    
            .name {
                margin-bottom: 0;
                font-size: rem(16px);
                font-weight: 500;
                position: sticky;
                top: 0;
                background-color: #fff;
                z-index: 10;
                @media (max-width: 1400px) {
                    font-size: rem(15px);
                }
            }
    
            .label-container {
                display: flex;
                align-items: center;
                gap: rem(11px);
                margin-top: rem(20px);
    
                .select-multiple-buttons {
                    .checkbox {
                        label {
                            span {
                                font-size: 14px;
                                border: 1px solid #A8A9A840;
                                padding: 8px 20px;
                                border-radius: 20px;
                                @media (max-width: 1837px) {
                                    // font-size: rem(16px);
                                    // padding: rem(8px) rem(10px);
                                }
                                @media (max-width: 1655px) {
                                    // font-size: rem(15px);
                                }
                                @media (max-width: 1521px) {
                                    // font-size: rem(14px);
                                }
                                @media (max-width: 1405px) {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }

            @media (max-width: 1900px) {
                width: 850px;
            }

            @media (max-width: 1850px) {
                width: 800px;
            }

            @media (max-width: 1800px) {
                width: 750px;
            }

            @media (max-width: 1650px) {
                width: 700px;
            }

            @media (max-width: 1600px) {
                width: 650px;
            }

            @media (max-width: 1570px) {
                width: 600px;
            }

            @media (max-width: 1520px) {
                width: 550px;
            }

            @media (max-width: 1490px) {
                width: 500px;
            }

            @media (max-width: 1440px) {
                width: 450px;
            }

            @media (max-width: 1380px) {
                width: 400px;
            }

            @media (max-width: 1340px) {
                width: 350px;
            }



            // @media (max-width: 1880px) {
            //     width: 600px;
            // }
            // @media (max-width: 1835px) {
            //     width: 550px;
            // }
            // @media (max-width: 1790px) {
            //     width: 500px;
            // }
            // @media (max-width: 1620px) {
            //     width: 450px;
            // }
            // @media (max-width: 1550px) {
            //     width: 400px;
            // }
            // @media (max-width: 1500px) {
            //     width: 350px;
            // }
            // @media (max-width: 1410px) {
            //     width: 300px;
            // }
            // @media (max-width: 1390px) {
            //     width: 260px;
            // }
        }

        @media (max-width: 700px) {
            flex-direction: column;
            gap: 10px;
        }
    }

    &__right {
        display: flex;
        gap: 5px;

        .first-half, .second-half {
            display: flex;
            gap: 5px;
            

            @media (max-width: 1840px) {
                justify-content: flex-start;
                // flex: 1;
                gap: 0;
            }
        }

        .second-half {
            padding-left: 10px;
        }

        .experts-container, .status-container, .scale-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 8px;

            .name {
                margin-bottom: 0;
                font-size: rem(16px);
                font-weight: 500;
                @media (max-width: 1400px) {
                    font-size: rem(15px);
                }
            }
            .expert-not-av {
                color: #5F2DEB;
            }
    
            .expert {
                display: flex;
                align-items: center;
                gap: rem(12px);
                margin-bottom: rem(30px);
                text-transform: capitalize;
    
                .expert-icon {
                    // width: rem(22px);
                    width: 22px;
                    padding: 1px;
                    border: 1px solid #CFCFCF;
                    border-radius: 50%;
                    overflow: hidden;
                    
                    img {
                        width: 100%;
                        border-radius: 50%;
                    }
                }

                .cursor {
                    cursor: pointer;
                }
    
                p {
                    margin-bottom: 0;
                    color: map-get($theme-colors, "primary");
                    font-size: rem(18px);
    
                    // @media (max-width: 1655px) {
                    //     font-size: rem(16px);
                    // }
                    // @media (max-width: 1521px) {
                    //     font-size: rem(15px);
                    // }
                }
            }

            @media (max-width: 520px) {
                width: 100%;
            }
        }

        .experts-container {
            // width: 130px;
            width: 150px;
            align-items: flex-start;
            padding-left: 12px;
            height: 100%;
            border-right: 1px solid map-get($theme-colors, grey-border-very-light);

            // @media screen and (max-width: 1470px) {
            //     width: 110px;
            //     padding-left: 8px;
            // }
        }

        .status-container {
            width: 130px;
            align-items: flex-start;
            padding-left: 12px;
            height: 100%;
            border-right: 1px solid map-get($theme-colors, grey-border-very-light);

            @media screen and (max-width: 1470px) {
                width: 110px;
                padding-left: 8px;
            }
            @media (max-width: 520px) {
                border-right: none;
            }
        }

        .scale-container {
            width: 120px;
            height: 100%;
            border-right: 1px solid map-get($theme-colors, grey-border-very-light);
            padding-right: 22px;
            padding-left: 22px;
            margin-right: 22px;
            @media (max-width: 1840px) {
                width: auto;
            }
            @media screen and (max-width: 1471px) {
                flex: 1;
            }
            @media (max-width: 520px) {
                border-right: 1px solid map-get($theme-colors, grey-border-very-light);
            }
        }

        .payment-container {
            border-right: none !important;
        }
    
        .button-container {
            display: flex;
            gap: 15px;
            align-items: center;

            &.justify-start {
                justify-content: flex-start;
            }
    
            button {
                // width: 124px;
                width: 103px;
                height: 46px;
                max-height: 60px;
                font-size: 15px;
                margin: 0;
                font-weight: 400;
                @media (max-width: 1837px) {
                    width: auto;
                    padding-inline: rem(30px);
                }
                @media (max-width: 1655px) {
                    padding-inline: rem(16px);
                }
            }

            .ignore-btn {
                background-color: #fff;
                border-color: #5f2deb;
                color: #5f2deb;
                
                &:hover {
                    background-color: #5f2deb;
                    border-color: #5f2deb;
                    color: #ffffff; 
                }
            }

            @media (max-width: 1590px) {
                gap: 7px;
            }
            @media (max-width: 1500px) {
                gap: 15px;
            }
        }

        @media (max-width: 1840px) {
            display: flex;
            flex: 1;
            gap: 0;
        }

        @media (max-width: 520px) {
            flex-direction: column;
        }
    }
}