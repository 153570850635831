/*Basic, low level typography*/

a {
  color: map-get($theme-colors, "default");
  text-decoration: none;
  transition: 0.3s all ease-in-out;
  @media screen and (min-width: 1025px) {
    &:hover {
      color: map-get($theme-colors, "primary");
    }
  }
}

@media screen and (max-width: 1025px) {
  a:hover {
    text-decoration: none !important;
  }
}

// font sizes
$h--1: (
  base: rem(72px),
  mobile: rem(40px),
  tablet: rem(50px),
  wide: rem(70px),
);
$h--2: (
  base: rem(48px),
  mobile: rem(33px),
  tablet: rem(38px),
  wide: rem(48px),
);
$h--3: (
  base: rem(32px),
  mobile: rem(22px),
  tablet: rem(25px),
  wide: rem(32px),
);
$h--4: (
  base: rem(24px),
  mobile: rem(20px),
  tablet: rem(22px),
  wide: rem(24px),
);
$h--5: (
  base: rem(20px),
  mobile: rem(17px),
  tablet: rem(19px),
  wide: rem(20px),
);
$h--6: (
  base: rem(16px),
  mobile: rem(15px),
  tablet: rem(15px),
  wide: rem(16px),
);
$p: rem(16px);
$p--large: rem(18px);
$p--medium: rem(15px);
$p--small: rem(14px);
$breakpoints: (
  mobile: 414px,
  tablet: 991px,
  wide: 1300px,
) !default;

$typography-elems: "ul", "ol";
$media-elems: ".blockquote", ".button-wrapper", ".media", ".table-wrapper";
// Headings
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
}


h1 {
  @include font-size($h--1);
}

h2 {
  @include font-size($h--2);
}

h3 {
  @include font-size($h--3);
}

h4 {
  @include font-size($h--4);
}

h5 {
  @include font-size($h--5);
}

h6 {
  @include font-size($h--6);
}

p {
  margin: 0 0 rem(20px) 0;
  line-height: 1.15;
  color: map-get($theme-colors, "default");
  font-size: rem(16px);
  span {
    color: map-get($theme-colors, "primary");
  }
}
.text-xs {
  font-size: 12px !important;
}
.text-lg {
  font-size: rem(18px);
}
.text-xl {
  font-size: rem(20px);
}
.font-light {
  font-weight: 300!important;
}
.font-regular {
  font-weight: 400!important;
}
.font-medium {
  font-weight: 500!important;
}
.font-sb {
  font-weight: 600!important;
}
.font-bold {
  font-weight: 700!important;
}

// Basic typography styling
#{$typography-elems},
#{$media-elems} {
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0;
  }
}

ol,
ul {
  padding: 0;
  margin: 0;
  // list-style:none;
}

// Stylistic elements
b,
strong {
  font-weight: 700;
}
