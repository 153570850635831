@mixin mq( $from: false, $to: false, $and: false, $media-feature: width, $media-type: screen, $bp: $breakpoints) {
    $min-width: 0;
    $max-width: 0;
    $media-query: '';
    // From: this breakpoint (inclusive)
    @if $from {
        @if type-of($from)==number {
            $min-width: $from;
        }
        @else {
            $min-width: map_get($bp, $from);
        }
    }
    // Until: that breakpoint (exclusive)
    @if $to {
        @if type-of($to)==number {
            $max-width: $to;
        }
        @else {
            $negative: if($em-media-queries, 0.01em, 1px);
            $max-width: map_get($bp, $to) - $negative;
        }
    }
    @if $media-feature {
        @if $min-width !=0 {
            $media-query: '#{$media-query} and (min-#{$media-feature}: #{$min-width})';
        }
        @if $max-width !=0 {
            $media-query: '#{$media-query} and (max-#{$media-feature}: #{$max-width})';
        }
    }
    @if $and {
        $media-query: '#{$media-query} and #{$and}';
    }
    // Remove unnecessary media query prefix 'all and '
    @if ($media-type=='all' and $media-query !='') {
        $media-type: '';
        $media-query: str-slice(unquote($media-query), 6);
    }
    @media #{$media-type + $media-query} {
        @content;
    }
}

@mixin prefix($property, $value, $prefixes: ()) {
    @each $prefix in $prefixes {
        -#{$prefix}-#{$property}: $value;
    }
    #{$property}: $value;
}
// Hide from both screenreaders and browsers
@mixin hidden {
    display: none;
    visibility: hidden;
}
@mixin visible($state: 'block') {
    display: unquote($state);
    visibility: visible;
}
//flex box
@mixin flex($vcenter: center, $hcenter: center, $wrap: wrap) {
    display: flex;
    flex-wrap: $wrap;
    align-items: $vcenter;
    justify-content: $hcenter;
}
@mixin flex-align-center {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
@mixin flex-justify-center {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
@mixin flex-space-around {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}
@mixin flex-space-between {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
@mixin font-size($sizes) {
    @each $breakpoint,
    $size in $sizes {
        @if $breakpoint==base {
            font-size: $size;
        }
        @else {
            @include mq($breakpoint) {
                font-size: $size;
            }
        }
    }
}
@mixin truncate($truncation-boundary) {
    max-width: $truncation-boundary;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
/// Horizontally and vertically centers block elements
@mixin center-both {
    position: absolute;
    top: 50%;
    left: 50%;
    @include prefix(transform, translate(-50%, -50%), 'webkit' 'ms');
}
@mixin truncate($lines,
$lh: 1.2,
$spacer:0) {
    line-height: $lh;
    max-height: $lh * $lines + $spacer + em;
    // overflow: hidden;
    text-overflow: ellipsis;
    text-overflow: -o-ellipsis-lastline;
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    /* number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
}