@import "_core/_assets/sass/utility/variables";

.payment-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: rem(50px);

    @media screen and (max-width: 991px) {
        flex-direction: column;
        align-items: flex-start;
    }
    .card {
        border: 1px solid #E8E8E8;
        border-radius: 10px;
        padding: rem(28px);
        box-shadow: unset;
        width: 100%;
        max-width: 467px;
        margin-right: rem(48px);
        position: relative;
        padding-bottom: rem(18px);
        @media screen and (max-width: 1600px) {
            margin-right: rem(30px);
        }
        @media screen and (max-width: 991px) {
            padding: rem(20px);
            padding-bottom: rem(14px);
            margin-right: rem(0px);
            margin-bottom: rem(25px);
        }
        h5 {
            font-size: rem(20px);
            font-weight: 400;
            @media screen and (max-width: 1600px) {
                font-size: rem(19px);
            }
            span {
                color: map-get($theme-colors, "primary");
                display: block;
                margin-top: rem(12px);
                letter-spacing: 2px;
            }
        }
        .eye-icon {
            position: absolute;
            right: rem(20px);
        }
    }
    > div {
        .btn-primary.sm {
            padding-block: rem(12.5px);
            padding-inline: rem(30px);
        }
    }
}