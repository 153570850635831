.dashboard-content {
    .header-container {
        border-bottom: 1px solid #D8DFF2;

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1rem 1rem;

            .title {
                font-size: calc(23px + (40 - 25) * ((100vw - 375px) / (1920 - 375)));
                color: map-get($theme-colors, "primary");
                font-weight: 400;
                margin-bottom: 0;

                .title-content {
                    @media (max-width: 460px) {
                     display: block;
                     margin-top: 2px;
                    }
                }

                span {
                    color: map-get($theme-colors, "default");
                    font-size: rem(26px);
                    font-weight: 400;
                    font-style: italic;
                    margin-left: rem(15px);
                }

                @media (max-width: 1024px) {
                    font-size: rem(30px);

                    span {
                        font-size: rem(20px);
                    }
                }

                @media (max-width: 550px) {
                    font-size: 20px;

                    span {
                        font-size: 14px;
                    }
                }
            }

            button {
                max-width: 220px;
                max-height: 60px;

                @media (max-width: 550px) {
                    padding-inline: rem(18px);
                    font-size: rem(15px);
                }
            }

            @media (max-width: 460px) {
                flex-direction: row;
                align-items: flex-start;
                gap: 10px;
            }

            @media (max-width: 1400px) and (max-height: 699px) {
                padding: 0.5rem 0.5rem;
                padding-left: 1rem;
                .title {
                    font-size: calc(19px + (40 - 25) * ((100vw - 375px) / (1920 - 375)));
                }
            }
        }
    }
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    z-index: 1111;

    input[type=file] {
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
    }
}

