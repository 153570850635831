.pricing-heading {
    display: flex;
    flex-direction: column;
    .price-heading {
        font-weight: bold;
        color: black;

        .title {
            color: #5F2DEB;
        }
    }
}