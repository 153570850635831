@import "../../../../_core//_assets/sass/utility/variables";

.transection-wrapper{
    display: flex;
    min-height: 74vh !important;
    flex-direction: column;
    justify-content: space-around;
    .inner-wrapper{
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-block: 52px;
        .paymentIcon{
            margin-bottom: 30px;
        }
        h3{
            font-weight: 500;
            @media (max-width: 1299px) {
                font-size: rem(25px) !important;
            }
        }
        p{
            font-weight: 400;
        }

        @media (max-height: 1054px) {
            margin-block: 47px !important;
        }
    }

    .button-row{
        display: flex;
        gap: 30px;
        .btn{
            width: 100%;
        }
    }
}