@import "_core/_assets/sass/utility/variables";

.dashboard-setting {
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(29px);
    border-radius: rem(16px);
    // height: calc(100% - 60px);
    height: calc(100% - 32px);
    margin: 30px;
    margin-left: 0;
    // margin-top: 39px;
    margin-top: 27px;
    margin-bottom: 10px;
    overflow: auto;
    &::-webkit-scrollbar {
        display: none;
    }
    @media (max-height: 700px) {
        margin-top: 18px;
        height: calc(100% - 18px);
    }
    @media (max-width: 600px) {
        margin: 0px;
    }
    .navTabs {
        padding: rem(42px) rem(30px);
        gap: calc(15px + (30 - 15) * ((100vw - 991px) / (1920 - 768)));
        border-bottom: 1px solid #D8DFF2;

        @media (max-width:767px) {
            flex-direction: column;
            padding: rem(35px) rem(30px);
        }
        @media (max-width: 1400px) and (max-height: 699px) {
            padding: rem(20px) rem(15px);
        }
        @media (max-width:575px) {
            flex-direction: column;
            // padding: rem(35px) rem(15px);
        }

        .nav-item {
            .nav-link {
                min-width: calc(300px + (484 - 300) * ((100vw - 991px) / (1920 - 768)));
                padding: rem(23px) rem(30px);
                border-radius: 10px !important;
                border: 1px solid #D6D6D6 !important;
                background-color: #fff;
                color: #191919;
                font-size: rem(20px);
                font-weight: 400;
                @media (max-width: 1400px) and (max-height: 699px) {
                    padding: rem(18px) rem(25px);
                    font-size: rem(15px);
                }
                @media (max-width:767px) {
                    width: 100%;
                    min-width: none;
                    padding: rem(18px) rem(25px);
                }

                &.active {
                    border-color: #5F2DEB !important;
                    color: #5F2DEB;
                    font-weight: 500;
                }

                @media (max-width: 1600px) {
                    min-width: calc(250px + (484 - 300) * ((100vw - 991px) / (1920 - 768)));
                }

                @media (max-width: 991px) {
                    min-width: calc(200px + (484 - 300) * ((100vw - 991px) / (1920 - 768)));
                }
            }
        }
    }

    .tab-content {
        padding: rem(42px) rem(30px);

        @media (max-width: 991px) {
            padding: 0 rem(30px);
        }
        @media (max-width: 1400px) and (max-height: 699px) {
            padding: rem(15px);
        }
        @media (max-width:575px) {
            flex-direction: column;
            padding: 0 rem(15px);
        }

        .profile-inner-wrapper {
            .img-uploader {
                display: flex;
                align-items: center;
                gap: 20px;
                @media (max-width: 575px) {
                    flex-direction: column;
                }

                &_left {
                    img {
                        width: 128px;
                        height: 128px;
                        object-fit: cover;
                        border-radius: 50%;
                        background-color: white;
                        border: 1px solid #707070;

                        @media (max-width: 991px) {
                            width: 75px;
                            height: 75px;

                        }
                    }
                }

                &_right {
                    p {
                        font-size: rem(20px);
                        font-weight: 500;
                    }
                    .upload-btn-wrapper {
                        .btn {
                            width: 176px;
                            height: 46px;
                            border-radius: 12px;
                            padding: rem(14px) rem(50px);
                        }
                    }
                }
            }


        }
    }

    .button-row {
        gap: 20px;
        justify-content: flex-start;
        padding: rem(24px) rem(32px);
        margin-top: 0;
        @media (max-width:575px) {
            // flex-direction: row !important;
            padding: rem(35px) rem(15px);
        }

        .btn {
            // padding: rem(24px) rem(32px);
            min-width: 134px;
            height: 46px;
            @media (max-width: 1400px) and (max-height: 699px) {
                padding: 14px 18px;
            }
        }
        @media (max-width: 1400px) and (max-height: 699px) {
            padding-left: 17px
        }
    }
}

.custom-form-wrapper {
    display: flex;
    column-gap: 66px;
    margin-top: 45px;
    max-width: 1000px;
    flex-wrap: wrap;

    .form-group {
        .control-label {
            font-size: rem(20px);
            font-weight: 500;
        }

        .form-control {
            height: rem(48px);
        }
    }
}

.custom-select {

    .css-t3ipsp-control {
        height: 48px;
        border-radius: 8px;
        border-color: #5F2DEB;
        box-shadow: unset;

        &:hover {
            border-color: #5F2DEB;
        }
    }

    .css-13cymwt-control {
        height: 48px;
        border-radius: 8px;
        border: 1px solid #A8A9A8;

        &:focus {
            height: 48px !important;
            border-color: #5F2DEB;
        }
    }

    .css-1nmdiq5-menu {
        .css-1n6sfyn-MenuList {
            .css-d7l1ni-option {
                &:active {
                    background-color: transparent;
                }
            }

            .css-tr4s17-option {
                background-color: #5F2DEB;
            }
        }
    }
}


.css-1p3m7a8-multiValue {
    padding: 8px !important;
    border-radius: 20px !important;
    background-color: #fff !important;
    border: 1px solid #CECECE;
}

.custom-multi-select {
    .css-t3ipsp-control {
        min-height: 48px;
        border-radius: 8px;
        border-color: #5F2DEB;
        box-shadow: unset;

        &:hover {
            border-color: #5F2DEB;
        }
    }

    .css-13cymwt-control {
        min-height: 48px;
        border-radius: 8px;
        border: 1px solid #A8A9A8;

        &:focus {
            // height: 48px !important;
            border-color: #5F2DEB;
        }
    }

    .css-1nmdiq5-menu {
        .css-1n6sfyn-MenuList {
            .css-d7l1ni-option {
                &:active {
                    background-color: transparent;
                }
            }

            .css-tr4s17-option {
                background-color: #5F2DEB;
            }
        }
    }
}
.change-password {
    label {
        font-size: rem(20px);
        font-weight: 500;
    }
}

.signin-password {
    position: relative;
    .password-field {
        padding-right: 25px;
    }
    .eye-password {
        cursor: pointer;
        position: absolute;
        right: 8px;
        top: 25px;
        @media (max-width: 1600px) {
            top: 17px;
        }
        @media (max-width: 991px) {
            top: 13px;
        }
    }
    input {
        &::-ms-reveal {
            display: none;
        }
    }
}