.button-row {
  @include flex(center, center);
  margin-top: rem(20px);
  &.not-stack {
    @media screen and (max-width: 575px) {
      flex-direction: row;
      .list-inline-item {
        &:not(:last-child) {
          margin-right: rem(20px);
          margin-bottom: 0;
        }
      }
    }
  }
  .list-inline-item {
    padding: 26px;
    
    &:not(:last-child) {
      margin-right: rem(23px);
      @media screen and (max-width: 575px) {
        margin-right: rem(0px);
        margin-bottom: rem(15px);
      }
    }
  }
  @media screen and (max-height: 1054px) {
    margin-top: rem(10px);
  }
  @media screen and (max-width: 575px) {
    flex-direction: column;
    justify-content: center;
  }
}
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  line-height: 1.06;
  transition: all 0.5s ease;
  font-size: rem(20px);
  font-weight: 500;
  padding: rem(24px) rem(62px);
  // height: rem(72px);
  vertical-align: middle;
  position: relative;
  // box-shadow: none !important;
  border-radius: 12px;
  @media screen and (max-width: 1600px) {
    padding: rem(21px) rem(50px);
    font-size: rem(19px);
  }
  @media screen and (max-width: 991px) {
    padding-inline: rem(45px);
  }
  &.sm {
    padding: rem(18px) rem(37px);
    // height: rem(59px);
    border-radius: 8px;
    @media screen and (max-width: 1600px) {
      padding: rem(17px) rem(35px);
    }
    @media screen and (max-width: 991px) {
      padding-inline: rem(25px);
    }
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    outline: none;
    box-shadow: none;
  }
  &:focus {
    box-shadow: none !important;
  }
  &.btn-default {
    background: map-get($theme-colors, "white");
    color: map-get($theme-colors, "primary");
    border: 1px solid map-get($theme-colors, "white");
    &:hover {
      background: map-get($theme-colors, "primary");
      color: map-get($theme-colors, "white");
      border-color: map-get($theme-colors, "primary");
    }
  }
  &.btn-primary {
    background: map-get($theme-colors, "primary");
    color: map-get($theme-colors, "white");
    border: 1px solid map-get($theme-colors, "primary");
    &:hover {
      background: map-get($theme-colors, "white");
      color: map-get($theme-colors, "primary");
    }
  }
  &.btn-secondary {
    border: 1px solid map-get($theme-colors, "primary");
    background: map-get($theme-colors, "white");
    color: map-get($theme-colors, "primary");
    &:hover {
      background: map-get($theme-colors, "primary");
      color: map-get($theme-colors, "white");
    }
  }
}

// checkBox
.checkbox , .radio{
  margin: 0;
  label {
    padding-left: 0;
    margin: 0;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    &:not(:last-child) {
      margin-right: rem(25px);
    }
  }
  input[type="checkbox"] {
    display: none;
    + span {
      display: inline-block;
      line-height: normal;
      position: relative;
      font-size: 16px;
      font-weight: 500;
      padding-left: 30px;
      color: map-get($theme-colors, "default");
      // img {
      //   vertical-align: text-top;
      //   margin-left: rem(3px);
      // }
      &:after {
        content: "";
        width: 21px;
        height: 21px;
        background: #fcfcfc;
        border: 1px solid #707070;
        border-radius: 6px;
        position: absolute;
        top: 0px;
        left: 0;
      }
    }
    &:checked {
      + span {
        &:before {
          content: "";
          position: absolute;
          top: 0px;
          left: 0px;
          line-height: 1;
          // background: map-get($theme-colors, "sky-blue");
          background: map-get($theme-colors, "primary");
          z-index: 1;
          background-image: url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.70711 2.29289C1.31658 1.90237 0.683418 1.90237 0.292893 2.29289C-0.0976311 2.68342 -0.0976311 3.31658 0.292893 3.70711L2.29289 5.70711C2.68342 6.09763 3.31658 6.09763 3.70711 5.70711L7.70711 1.70711C8.09763 1.31658 8.09763 0.683418 7.70711 0.292893C7.31658 -0.0976311 6.68342 -0.0976311 6.29289 0.292893L3 3.58579L1.70711 2.29289Z' fill='white'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          background-position: center;
          width: 21px;
          height: 21px;
          border-radius: 6px;
          background-size: 14px;
        }
      }
    }
  }
}

.default-radio {
  input[type=radio] {
    appearance: radio;
    accent-color: map-get($theme-colors, "primary");
    width: rem(18px);
    height: rem(18px);
    + span {
      display: inline-block;
      line-height: normal;
      position: relative;
      font-size: rem(20px);
      font-weight: 400;
      padding-left: rem(7px);
      color: map-get($theme-colors, "default");
      @media screen and (max-width: 1600px) {
        font-size: rem(18px);
      }
      @media screen and (max-width: 1200px) {
        font-size: rem(16px);
      }
    }
  }
}


.select-multiple-buttons {
  flex-wrap: wrap;
  .radio , .checkbox {
      margin-bottom: 8px;
      &:not(:last-child) {
          margin-right: rem(8px);
      }
      input[type="radio"] , input[type="checkbox"] {
        appearance: none;
          &:checked {
              +span {
                  color: #ffffff;
                  background-color: map-get($theme-colors, "primary");
                  border-color: transparent;
                  &:before {
                      content: none;
                  }
              }
          }
          +span {
              padding-left: 0;
              font-size: rem(20px);
              font-weight: 300;
              color: #707070;
              border: 1px solid #f3eeee;
              padding: 8px rem(19.5px);
              border-radius: 20px;
              height: auto;
              width: auto;
              display: flex;
              align-items: center;
              justify-content: center;
              box-shadow: 2px 6px 6px #00000008;
              &:after {
                  content: none;
              }
              @media screen and (max-width: 1600px) {
              padding: 8px rem(15px);
              font-size: rem(18px); 
              }
              @media screen and (max-width: 1200px) {
              padding: 7px rem(15px);
                font-size: rem(16px);
              }
              @media screen and (max-width: 991px) {
                padding: 6px rem(12.5px);
                  font-size: rem(14px);
                }
          }
      }
  }
}