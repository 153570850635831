@import "_core/_assets/sass/utility/variables";

.reviews {
    .reviews_listing {
        .reviews_card {
            text-align: center;
            background-color: map-get($theme-colors, "white");
            position: relative;
            padding: 15px;
            min-height: 15rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border: 3px solid #5F2DEB;
            border-radius: 1.75rem;
            margin-bottom: 0.9375rem;
            overflow: hidden;
        }

        .reviews_item--img {
            width: rem(116px);
            height: rem(116px);
            margin: auto;
            position: relative;
            margin-bottom: rem(-58px);
            z-index: 9;
            border: 2px solid #FFFFFF;
            border-radius: 100%;
        }

        .reviews_item--desc {
            p {
                font-size: rem(16px);
                font-weight: 500;
                margin: 0;
                margin-top: rem(30px);
                line-height: 1.4;
                @media screen and (max-width: 1600px) {
                    margin-top: rem(25px);
                }
                @media screen and (max-width: 991px) {
                    margin-top: rem(20px);
                }
            }
        }

        .review_heading {
            font-size: 1rem;
            font-weight: 700;
            margin-bottom: 0;
            margin-top: 15px;
        }
    }

    .slick-list {
        margin: 0 -8.5px ;
    }

    .slick-slide {
        text-align: center;
        >div {
            padding: 0 8.5px ;
        }
    }
}