@import "_core/_assets/sass/utility/variables";

.ourPartners {
    .ourPartners_listing {
        img {
            margin: auto;
        }
    }

    .slick-slider .slick-list .slick-track {
        display: flex;
        justify-content: space-between;

        .slick-slide:nth-child(4) {
            width: 100px !important;
            margin-right: 10px;

            @media (max-width: 991px) {
                width: 234px !important;
            }

            @media (max-width: 414px) {
                width: 303px !important;
            }
        }

        @media (max-width: 991px) {
            justify-content: unset;
        }
    }
}
