@import "_core/_assets/sass/utility/variables";

.mainBanner {
    padding: 100px 0;
    @media screen and (max-width: 1600px) {
        padding: rem(80px) 0;
    }
    @media screen and (max-width: 991px) {
        padding: rem(50px) 0;
    }
    h1 {
        font-size: rem(72px);
        font-weight: 400;
        margin-bottom: rem(18px);

        @media screen and (max-width: 1600px) {
            font-size: rem(60px);
        }
        @media screen and (max-width: 991px) {
            font-size: rem(50px);
        }
        @media screen and (max-width: 575px) {
            font-size: rem(40px);
        }
        span {
            color: map-get($theme-colors, "primary");
        }
    }
    p {
        font-size: rem(28px);
        max-width: 686px;
        margin-inline: auto;
        @media screen and (max-width: 1600px) {
            font-size: rem(24px);
        }
        @media screen and (max-width: 1200px) {
            font-size: rem(22px);
        }
        @media screen and (max-width: 575px) {
            font-size: rem(20px);
        }
        span {
            color: map-get($theme-colors, "primary");
        }
    }
    .button-row {
        margin-top: rem(60px);
        @media screen and (max-width: 1600px) {
            margin-top: 3.6rem;
        }
        @media screen and (max-width: 991px) {
            margin-top: rem(35px);
        }
        .btn {
            @media screen and (max-width: 575px) {
                min-width: 235px;
            }
        }
    }
    .noteBar {
        padding: 26px;
        @media screen and (min-width: 1401px) {
            margin-top: 15rem !important;
        }
        @media screen and (min-width: 1801px) {
            margin-top: rem(180px);
        }
    }

    .heading-line {
        color: black;
        font-weight: 300;
    }
}

.featureListing {
    @media screen and (max-width: 767px) {
        margin-bottom: rem(25px);
    }
    .featureListing-image {
        margin-bottom: rem(19px);
    }
    .featureListing-desc {
        h5 {
            font-size: rem(20px);
            font-weight: 400;
            margin-bottom: rem(14px);
            @media screen and (max-width: 1600px) {
                font-size: rem(19px);
            }
        }
        p {
            color: map-get($theme-colors, "primary");
            line-height: 1.2;
        }
    }
}

.aboutFounders {
    .image {
        @media screen and (max-width: 1600px) {
            max-width: 800px;
            margin: auto;
        }
    }
    .button-row {
        margin-top: rem(70px);
        @media screen and (max-width: 1600px) {
            margin-top: rem(40px);
        }
        @media screen and (max-width: 991px) {
            margin-top: rem(35px);
        }
    }
}

.bg-color-gradient-1 {
    background: transparent linear-gradient(119deg, #D4FFF6 0%, #C1BCFF 100%) 0% 0%;
    position: relative;
    &:after {
        content: "";
        background: transparent linear-gradient(19deg, #D4FFF6 0%, #C1BCFF 100%) 0% 0%;
        top: -135px;
        left: 0;
        position: absolute;
        width: 100%;
        height: 135px;
        @media screen and (max-width: 991px) {
            height: 90px;
            top: -90px;
        }
    }

}
.bg-color-gradient-2 {
    background: transparent linear-gradient(60deg, #D4FFF6 0%, #C1BCFF 100%) 0% 0%;
}

.bg-color-gradient-3 {
    background: transparent linear-gradient(39deg, #D4FFF6 0%, #C1BCFF 100%) 0% 0%;
}

.bg-color-gradient-4 {
    background: transparent linear-gradient(194deg, #D4FFF6 0%, #C1BCFF 100%) 0% 0%;
}

.body-height {
    @media (max-height: 722px) {
      height: calc(100vh + 90px);
    }
  }