@import "_core/_assets/sass/utility/variables";

.auth-screen {
    padding-bottom: rem(38px);
    background: transparent linear-gradient(119deg, #D4FFF6 0%, #C1BCFF 100%) 0% 0%;
    min-height: 100vh;
    .container {
        section {
            @media (max-height: 1054px) {
                padding-top: 20px;
                padding-bottom: 10px;
            }
        }
    }
    h2 {
        max-width: 784px;
        margin-inline: auto;
    }
    h3 {
        font-size: rem(40px);
        margin-bottom: rem(48px);
        @media screen and (max-width: 1600px) {
            font-size: rem(36px);
            margin-bottom: rem(35px);
        }
        @media screen and (max-width: 991px) {
            font-size: rem(34px);
            margin-bottom: rem(32px);
        }
        @media screen and (max-width: 575px) {
            font-size: rem(32px);
            margin-bottom: rem(30px);
        }
    }
    .card {
        &.signup-Card {
            .card-title {
                font-size: rem(28px);
                color: map-get($theme-colors, "default");
                font-weight: 500;
                margin-bottom: 15px;
            }
            .image {
                max-height: 189px;
                text-align: center;
                margin-bottom: 11px;

                img {
                    height: 179px;
                }
            }

            p {
                margin-bottom: 0;
                text-align: left;
            }

            .button-row {
                margin-top: rem(18px);

                .btn {
                    padding-inline: rem(25px);
                    width: 100%;
                }

                .yellow {
                    background-color: map-get($theme-colors, "yellow-secondary");
                    color: map-get($theme-colors, "primary");
                    border-color: transparent;
                    transition: all 0.5s ease;

                    &:hover {
                        background-color: map-get($theme-colors, "white");
                        border-color: map-get($theme-colors, "yellow-secondary");
                        color: map-get($theme-colors, "yellow-secondary");
                    }
                }
            }
            @media screen and (max-width: 575px) {
                margin-bottom: rem(25px);
            }
        }
        .btn {
            width: 100%;
        }
    }
    .forgot-pw {
        margin-top: rem(18px);
        display: inline-block;
        text-align: center;
        text-decoration: underline;
    }
    .card {
        &.signin-Card {
            max-width: 428px;
            background: #FBF9FF;
            @media screen and (min-width: 992px) {
                margin-left: auto;
                padding-top: rem(38px);
                padding-bottom: rem(90px);
            }
            @media screen and (min-width: 1601px) {
                margin-left: auto;
                padding-top: rem(44px);
                padding-bottom: rem(114px);
            }
            @media screen and (min-width: 1201px) {
                margin-left: 15px;
            }
            .button-row {
                margin-top: rem(49px);
                @media screen and (max-width: 1600px) {
                    margin-top: rem(35px);
                }
            }
            @media screen and (max-width: 991px) {
                margin-inline: auto;
                margin-bottom: rem(40px);
            }

            .signin-password {
                position: relative;
                .password-field {
                    padding-right: 25px;
                }
                .eye-password {
                    cursor: pointer;
                    position: absolute;
                    right: 8px;
                    top: 25px;
                    @media (max-width: 1600px) {
                        top: 17px;
                    }
                    @media (max-width: 991px) {
                        top: 13px;
                    }
                }
                input {
                    &::-ms-reveal {
                        display: none;
                    }
                }
            }
        }
    }
    .copyright {
        font-weight: 500;
        font-size: 14px;
        margin-top: 10px;
        @media (max-height: 1054px) {
            margin-top: rem(10px) !important;
        }
    }
    .error{
        color: red;
        padding-top: 10px;
    }

    .auth-cards-container {
        margin-top: 4rem;
        margin-bottom: 3rem;
        align-items: flex-end !important;
    }
}

.error-field {
    margin-top: 8px;
}