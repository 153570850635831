@import '../../../_core/_assets/sass/import';

.dashboard-sidebar {
    width: 306px;
    min-width: 306px;
    padding-block: 30px;
    border-radius: 16px;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(29px);
    // height: calc(100% - 60px);
    // height: calc(100vh - 133px);
    height: calc(100vh - 43px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    margin: 30px;
    margin-bottom: 10px;
    transition: all 0.15s;

    .sidenav {
        list-style: none;

        .nav-item {
            // padding: rem(18px);

            p {
                margin-bottom: 0;
                font-size: 17px;
            }

            a {
                color: #191919;
                display: flex;
                align-items: center;
                gap: 17px;
                font-weight: 500;
                font-size: 17px;
                padding: rem(18px);
                @media (max-width: 1730px) {
                    font-size: 15px;
                }
            }
            svg {
                width: 20px;
                height: 20px;
            }

            &.active {
                background-color: map-get($theme-colors, "primary");
                
                a {
                    color: map-get($theme-colors, "white");
                }

                p {
                    color: map-get($theme-colors, "white"); 
                }

                svg {
                    path {
                        fill: map-get($theme-colors, "white");
                    }
                }
            }
        }
    }

    @media (max-width: 1730px) {
        width: 260px;
        min-width: 260px;
    }

    @media (min-width: 500px) and (max-width: 669px) {
        width: 175px !important;
        min-width: 175px !important;
        margin-right: 0 !important;
      }

    @media (min-width: 670px) and (max-width: 991px) {
        width: 220px !important;
        min-width: 220px !important;
      }

    @media (max-width: 1200px) {
        margin-right: 15px;
    }

    @media (max-width: 600px) {
        display: none;
    }
}

.hide-sidebar {
    width: 50px;
    min-width: 50px;
    transition: all 0.15s;
    border-radius: 8px;
    box-shadow: none !important;

    p {
        font-size: 0 !important;
        opacity: 0 !important;
        display: none;
    }

    svg {
        display: block;
    }

}

.abs {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.5);
    transition: all 0.15s;
    -webkit-box-shadow: 2px 2px 5px -1px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 2px 5px -1px rgba(0,0,0,0.75);
    box-shadow: 2px 2px 5px -1px rgba(0,0,0,0.75);
}