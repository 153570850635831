@import "_core/_assets/sass/utility/variables";

.technologies {
    padding-bottom: rem(40px);
    .technologies_item {
        overflow: hidden;
        position: relative;
        max-width: 190px;
        margin: auto;
        transition: .3s all ease-in-out;
        .technologies_item--name {
            background: transparent linear-gradient(0deg, #5F2DEB 0%, #A484FF66 100%) 0% 0%;
            &.dark-gradient {
                background: transparent linear-gradient(0deg, #5F2DEB 0%, rgba(183, 157, 255, 0.65) 100%) 0% 0%;
            }
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            font-weight: 500;
            font-size: rem(24px);
            padding: 10px;
            border-radius: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            height: 100%;
            width: 100%;
            text-align: center;
            transition: .3s all ease-in-out;
            opacity: 1;
            transform: translateY(100%);
            @media screen and (max-width: 1600px) {
                font-size: rem(22px);
            }
        }
        &:hover {
            .technologies_item--name {
                transform: translateY(0);
            }
        }
    }
    .slick-slide {
        text-align: center;
    }
}

