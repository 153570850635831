// CORE
// basic building blocks, typically single html tags
@import "utility/variables";
// Include bootstrap;
@import "bootstrap/scss/bootstrap.scss";
// Import css files
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "aos/dist/aos.css";
@import "utility/functions";
@import "base/base";
@import "core/_button.scss";
@import "core/_typography";
@import "core/form";
@import "common/_common";
@import "addan-styles";

