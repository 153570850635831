@import '../../../_core/_assets/sass/import';

.dashboard-header {
    position: relative;
    height: 100px;
    
    .header-left {
        display: flex;
        align-items: center;
        gap: 10px;

        .hamburger-menu {
            display: none;
            
            .hamburger {
                height: 32px;
                width: 40px;
                cursor: pointer;
                position: relative;

                span,
                span::before,
                span::after {
                    background: #5F2DEB;
                    border-radius: 3px;
                    content: '';
                    position: absolute;
                    width: 30px;
                    height: 3px;
                    margin-top: 13px;

                    -webkit-transition: .3s ease-in-out;
                    -moz-transition: .3s ease-in-out;
                    -o-transition: .3s ease-in-out;
                    transition: .3s ease-in-out;
                }

                span {
                    &::before {
                        margin-top: -7px;
                    }

                    &::after {
                        margin-top: 7px;
                    }
                }

                &.active {
                    span {
                        background: transparent;

                        &::before {
                            margin-top: 0;
                            transform: rotate(45deg);
                        }

                        &::after {
                            margin-top: 0;
                            transform: rotate(-45deg);
                        }
                    }
                }
            }

            @media (max-width: 600px) {
                display: block;
            }
        }
        .logo{
            .mobile-logo{
                svg{
                    width: rem(40px);
                    height: rem(40px);
                }
            }
        }
    }

    .header-right {
        display: flex;
        align-items: center;

        .bell-icon {
            width: rem(21px);
            height: rem(26px);
            margin-right: rem(30px);
            cursor: pointer;
            position: relative;

            img {
                width: 100%;
            }

            .dot {
                position: absolute;
                right: 2px;
                top: 0;
                width: 8px;
                height: 8px;
                background-color: #F47690;
                border-radius: 50%;
            }

            @media (max-width: 991px) {
                width: 22px;
                height: 20px;
            }
        }

        .avatar {
            width: rem(64px);
            height: rem(64px);
            border-radius: 50%;
            overflow: hidden;
            margin-right: rem(13px);

            img {
                height: 100%;
                object-fit: cover;
                width: 100%;
            }

            @media (max-width: 600px) {
                width: rem(48px);
                height: rem(48px);
                margin-right: rem(8px);
            }
        }

        .custom-dropdown {
            color: map-get($theme-colors, "default");
            font-size: rem(18px);
            margin-bottom: 0;

            .dropdown {
                button {
                    padding: 5px !important;
                    border: unset !important;
                    box-shadow: none !important;
                }
            }
        }
    }

    @media (max-width: 991px) {
        height: 70px !important;
    }
}