@import "_core/_assets/sass/utility/variables";

.wallet-wrapper {
    .card {
        border: 1px solid #a8a9a838;
        background-color: rgba($color: #ffffff, $alpha: 0.25);
        border-radius: 10px;
        padding: rem(35px) rem(28px);
        box-shadow: unset;
        // margin-right: rem(48px);
        position: relative;
        padding-bottom: rem(18px);
        width: 100%;
        height: calc(100% - 25px);
        margin-bottom: 25px;
        @media screen and (max-width: 1600px) {
            padding: rem(30px) rem(25px);
        }
        @media screen and (max-width: 767px) {
            padding: rem(30px) rem(20px);
        }
        h4 {
            display: flex;
            flex-direction: column;
            font-size: rem(40px);
            font-weight: 400;
            margin-bottom: 0;
            @media screen and (max-width: 1600px) {
                font-size: rem(36px);
            }
            @media screen and (max-width: 991px) {
                font-size: rem(32px);
            }
            label {
                font-size: rem(20px);
                display: block;
                margin-top: rem(10px);
                @media screen and (max-width: 1600px) {
                    font-size: rem(19px);
                }
                @media screen and (max-width: 1200px) {
                    font-size: rem(18px);
                }
            }
        }
        .btn-primary.sm {
            padding-block: rem(12.5px);
            padding-inline: rem(30px);
        }
        .wallet-icon {
            margin-top: rem(15px);
        }
        .detail-list {
            display: flex;
            @media screen and (max-width: 991px) {
                margin-top: 8px;
            }
            ul {
                margin-bottom: 15px;
                &:not(:last-child) {
                    margin-right: rem(60px);
                    @media screen and (max-width: 1600px) {
                        margin-right: rem(35px);
                    }
                    @media screen and (max-width: 767px) {
                        margin-right: rem(25px);
                    }
                    @media screen and (max-width: 575px) {
                        margin-right: rem(23px);
                    }
                }
                li {
                    font-size: rem(20px);
                    @media screen and (max-width: 1600px) {
                        font-size: rem(19px);
                    }
                    @media screen and (max-width: 1200px) {
                        font-size: rem(18px);
                    }
                    @media screen and (max-width: 575px) {
                        font-size: rem(16px);
                    }
                    &:last-child {
                        margin-top: rem(12px);
                        color: map-get($theme-colors, "primary");
                    }
                }
            }
        }
        // @media screen and (max-width: 1600px) {
        //     margin-right: rem(30px);
        // }
        // @media screen and (max-width: 767px) {
        //     padding: rem(20px);
        //     padding-bottom: rem(14px);
        //     margin-right: rem(0px);
        //     margin-bottom: rem(25px);
        // }
        

        
    }

    
}