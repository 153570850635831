@import "_core/_assets/sass/utility/variables";


.packages {
    background: transparent linear-gradient(116deg, #D4FFF6 0%, #C1BCFF 100%) 0% 0%;
    padding-bottom: 25px;
    .sectionTitle {
        max-width: 784px;
        margin-inline: auto;
    }
    .packagesWrapper {
        border-radius: rem(32px);
        background-color: map-get($theme-colors, "white");
        margin-top: rem(150px);
        margin-bottom: rem(80px);
        display: flex;
        flex-direction: column;
        .packageListingWrapper {
            display: flex;
            justify-content: space-between;
            .packageListing {
                background-color: map-get($theme-colors, "white");
                padding: rem(66px) rem(59px) rem(31px);
                border-radius: rem(32px);
                overflow: hidden;
                @media screen and (max-width: 1600px) {
                    padding: rem(60px) rem(30px) rem(31px);
                }
                @media screen and (max-width: 991px) {
                    padding: rem(40px) rem(30px) rem(30px);
                }
                .left-button {
                    margin-top: 5.3rem !important;
                    @media screen and (max-width: 1200px) {
                        margin-top: 6.5rem !important;
                    }
                    @media (max-width: 1300px) and (min-width: 1200px) {
                        margin-top: 4.0625rem !important
                    }
                }
                .button-row {
                    margin-top: rem(70px);
                    @media screen and (max-width: 1600px) {
                        margin-top: rem(65px);
                    }
                    @media screen and (max-width: 1200px) {
                        margin-top: rem(45px);
                    }
                    .btn {
                        @media screen and (min-width: 992px) {
                            width: 100%;
                            padding-inline: 30px;
                        }
                    }
                }
                h3 {
                    color: map-get($theme-colors, "primary");
                    font-size: rem(40px);
                    font-weight: 700;
                    margin-bottom: rem(16px);
                    @media screen and (max-width: 1600px) {
                        font-size: rem(37px);
                    }
                    @media screen and (max-width: 1200px) {
                        font-size: rem(34px);
                    }
                }
                p {
                    font-size: rem(20px);
                    @media screen and (max-width: 1300px) {
                        font-size: rem(19px);
                    }
                }
                ul {
                    border-top: 2px solid map-get($theme-colors, "default");
                    padding-top: rem(20px);
                    li {
                        margin-bottom: rem(18px);
                        font-size: rem(18px);
                        font-weight: 500;
                        display: flex;
                        @media screen and (max-width: 1300px) {
                            font-size: rem(17px);
                        }
                        img {
                            margin-right: 8px;
                        }
                    }
                }
                h5 {
                    color: map-get($theme-colors, "primary");
                    text-align: center;
                    font-weight: 400;
                    font-size: rem(20px);
                    margin-top: rem(18px);
                    line-height: 1.4;
                    @media screen and (max-width: 1300px) {
                        font-size: rem(19px);
                    }
                }
                &.highlight {
                    background-color: map-get($theme-colors, "primary");
                    box-shadow: 0px 86px 66px #1C0B4E2B;
                    position: relative;
                    padding-top: rem(90px);
                    transition: .8s all ease-in-out;
                    @media screen and (min-width: 992px) {
                        // animation: MoveUpDown 3s linear infinite;
                        top: rem(-65px);
                    }
                    @media screen and (max-width: 1600px) {
                        padding-top: rem(84px);
                    }
                    @media screen and (max-width: 991px) {
                        padding-top: rem(40px);
                    }
                    > * {
                        color: map-get($theme-colors, "white")!important;
                    }
                    ul {
                        border-top-color: map-get($theme-colors, "white");
                    }
                }
            }
            @media screen and (max-width: 991px) {
                flex-direction: column;
            }
        }
        h5 {
            color: map-get($theme-colors, "primary");
            text-align: center;
            font-weight: 400;
            font-size: rem(20px);
            line-height: 1.4;
            margin-bottom: 1.5rem;
            font-weight: 600;
            @media screen and (max-width: 1300px) {
                font-size: rem(19px);
            }
        }
        @media screen and (max-width: 1600px) {
            margin-top: rem(120px);
            margin-bottom: rem(70px);
        }
        @media screen and (max-width: 991px) {
            margin-top: rem(40px);
            max-width: 450px;
            margin-inline: auto;
        }
    }
}

// @keyframes MoveUpDown {
//     0%, 100% {
//       top: -65px;
//     }
//     50% {
//       top: 0px;
//     }
//   }
