@import "../../../_core//_assets/sass/utility/variables";

.billing-screen {
    height: 100vh;
    .align-items-flex-start {
        align-items: flex-start !important;
    }
    .btn {
        width: 100%;
    }

    h2 {
        font-size: rem(40px);
        color: #5F2DEB;
        margin-bottom: rem(30px);

        @media (max-height: 1054px) {
            margin-bottom: rem(17px);
        }
    }

    .paymentMethod {
        .billing-wrapper {
            display: flex;
            justify-content: space-between;
            padding-bottom: rem(20px);
            margin-bottom: rem(20px);
            border-bottom: 1px solid #70707045;


            p {
                line-height: rem(19px);
                margin: 0;
                font-weight: 400;

                span {
                    font-weight: 600;
                }
            }

            &__left {
                p {
                    &:first-child {
                        font-weight: 400;
                        margin-bottom: rem(10px);

                        @media (max-height: 1054px) {
                            margin-bottom: rem(5px);
                        }
                    }

                    &:nth-child(2) {
                        font-weight: 300;
                    }
                }
            }

            &__right {
                text-align: end;

                p {
                    font-size: rem(20px);
                    color: map-get($theme-colors , primary);

                    &.sm {
                        font-size: rem(14px);
                    }

                    &:first-child {
                        margin-bottom: rem(10px);

                        @media (max-height: 1054px) {
                            margin-bottom: rem(5px);
                        }
                    }
                }
            }

            @media (max-height: 1054px) {
                margin-bottom: rem(10px);
                padding-bottom: rem(10px);
            }
        }

        h3 {
            margin-bottom: rem(20px);

            &:not(:first-child) {
                margin-top: rem(10px);
            }
            @media (max-height: 1054px) {
                margin-bottom: rem(10px);
            }
        }

        .form-group-wrapper {
            display: flex;
            gap: 20px;

            .form-group {
                &:first-child {
                    width: calc(100% - 156px);
                }

                &:nth-child(2) {
                    width: 156px;
                }
            }
        }
    }

    .copyright {
        margin-top: rem(30px);
        @media (max-height: 1054px) {
            margin-top: rem(10px);
        }
    }

    .radio-buttons {
        flex-wrap: wrap;

        .radio {
            // margin-bottom: rem(30px);

            label {
                display: flex;
                gap: 15px;
                padding: rem(18px);
                border-radius: 16px;
                background-color: white;

                // do not delete this commented css without discussing with UI Engineer
                // &.selected {
                //     outline: 2px solid map-get($theme-colors, "primary");
                // }
                // &:first-child {
                //     border-bottom-left-radius: 0 !important;
                //     border-bottom-right-radius: 0 !important;
                // }
            }

            input[type="radio"] {
                appearance: none;
                background-color: #ffffff;
                width: 20px;
                height: 20px;
                border: 1px solid #cccfdb;
                margin: 0;
                border-radius: 50%;
                display: grid;
                align-items: center;
                justify-content: center;
                transition: all 0.3s ease;

                &::before {
                    content: "";
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    transform: scale(0);
                    transition: 0.3s transform ease-in-out;
                    box-shadow: inset 15px 15px #5F2DEB;
                }

                &:checked {
                    background: white;
                    border-color: #5F2DEB;

                    &::before {
                        transform: scale(0.5);
                    }
                }

                &:checked {
                    +span {
                        color: #191919;

                        // outline: 2px solid map-get($theme-colors, "primary");
                        &:before {
                            content: none;
                        }
                    }
                }

                +span {
                    padding-left: 0;
                    font-size: rem(16px);
                    font-weight: 300;
                    color: #191919;
                    // border: 1px solid #f3eeee;
                    // background-color: white;
                    // padding: rem(20px);
                    // border-radius: 16px;
                    height: auto;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &:after {
                        content: none;
                    }

                    // @media screen and (max-width: 1600px) {
                    //     padding: 8px rem(15px);
                    //     font-size: rem(18px);
                    // }

                    // @media screen and (max-width: 1200px) {
                    //     padding: 7px rem(15px);
                    //     font-size: rem(16px);
                    // }

                    // @media screen and (max-width: 991px) {
                    //     padding: 6px rem(12.5px);
                    //     font-size: rem(14px);
                    // }
                }
            }
        }

        .amount {
            font-size: rem(20px);
            font-weight: 600;
            color: map-get($theme-colors , primary);

            span {
                font-weight: 400;
            }
        }

    }

    .container {
        section {
            @media (max-height: 1054px) {
                padding-top: 20px !important;
            }
            .row > *, .choose-your-plan, .col-lg-7, .col-md-6 {
                @media (max-height: 1054px) {
                    padding-top: 20px !important;
                }
            }
        }
    }
}

.error {
    color: red;
}

.stripe-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
}

.rm-top-borders {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.rm-bottom-borders {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

#add-bottom-borders {
    border-bottom-left-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
}

.no-borders {
    border-radius: 0 !important;
}