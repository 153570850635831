
@import "_core/_assets/sass/utility/variables";

.tipsTemplates {
    text-align: center;
    background: transparent linear-gradient(176deg, #D4FFF6 0%, #C1BCFF 100%) 0% 0%;
    @media screen and (max-width: 1600px) and (min-width: 992px) {
        padding-bottom: rem(40px);
    }
    .tipsTemplates_listing {
        overflow: hidden;
        max-width: 301px;
        margin: auto;
        margin-bottom: 15px;
        @media screen and (max-width: 1600px) {
            max-width: 275px;
        }
        .tipsTemplates--img {
            border: 3px solid map-get($theme-colors, "primary");
            border-radius: 28px;
            margin-bottom: rem(28px);
            overflow: hidden;
            @media screen and (max-width: 1600px) {
                margin-bottom: rem(23px);
            }
            img {
                width: 100%;
                height: 100%;
            }
        }
        .tipsTemplates--desc {
            p {
                font-size: rem(20px);
                font-weight: 500;
                margin-bottom: 0;
                line-height: 1.4;
                @media screen and (max-width: 1600px) {
                    font-size: rem(19px);
                }
            }
        }
    }
    .slick-list {
        margin: 0 -16px ;
    }
    .slick-slide {
        text-align: center;
        >div {
            padding: 0 16px ;
        }
    }
    .noteBar {
        line-height: 1.4;
        padding: rem(24px) rem(75px);
        max-width: 1120px;
        margin-bottom: rem(40px);
        @media screen and (max-width: 991px) {
            margin-bottom: rem(25px);
            padding: rem(18px) rem(40px);
        }
        @media screen and (max-width: 575px) {
            padding: rem(18px) rem(20px);
        }
    }
}

