@import "_core/_assets/sass/utility/variables";


.WhoWeare {

    .container {
        .who-we-are-container {
            align-items: baseline;
            .image-container {
                img {
                    height: 600px;
                    @media (max-width: 1600px) {
                        height: auto;
                    }
                }
            }
            .content-container {
                align-self: end;
                margin-bottom: 25px;

                @media (max-width: 1600px) {
                    align-self: center;
                    margin-bottom: 0;
                }
            }
        }
    }
    @media screen and (max-width: 1600px) {
        padding-top: rem(15px);
    }
    @media screen and (max-width: 991px) {
        padding-top: rem(25px);
    }
    .image {
        @media screen and (max-width: 767px) {
            margin-bottom: rem(30px);
            text-align: center;
        }
    }
    
    .row {
        + .row {
            margin-top: rem(50px);
            @media screen and (min-width: 992px) {
                margin-top: rem(60px);
            }
            @media screen and (min-width: 1301px) {
                margin-top: rem(70px);
            }
            @media screen and (min-width: 1601px) {
                margin-top: rem(150px);
            }
            

        }
    }
    h3 {
        font-size: rem(60px);
        margin-bottom: rem(20px);
        @media screen and (max-width: 1600px) {
            font-size: rem(45px);
            margin-bottom: rem(16px);
        }
        @media screen and (max-width: 991px) {
            font-size: rem(40px);
        }
    }
    h4 {
        font-size: rem(24px);
        font-weight: 400;
        margin-bottom: rem(30px);
        @media screen and (max-width: 1600px) {
            font-size: rem(22px);
            margin-bottom: rem(25px);
        }
        @media screen and (max-width: 991px) {
            font-size: rem(20px);
        }
    }
    p {
        font-size: rem(18px);
        opacity: 0.7;
        margin-bottom: rem(0px);
        line-height: 1.4;
        @media screen and (max-width: 1600px) {
            font-size: rem(17px);
        }
        &:not(:last-child) {
            margin-bottom: rem(32px);
            @media screen and (max-width: 1600px) {
                margin-bottom: rem(25px);
            }
            @media screen and (max-width: 991px) {
                margin-bottom: rem(20px);
            }
        }
    }
    .button-row {
        justify-content: flex-start;
    }
}

.OurFounders {
    .card {
        border-radius: rem(32px);
        padding: rem(60px) rem(50px);
        .sectionTitle {
            font-size: rem(60px);
            @media screen and (max-width: 1600px) {
                font-size: rem(45px);
                margin-bottom: rem(16px);
            }
            @media screen and (max-width: 991px) {
                font-size: rem(40px);
            }
        }
        @media screen and (min-width: 1301px) {
            padding-right: rem(55px);
        }
        @media screen and (min-width: 1601px) {
            padding-right: rem(90px);
        }
        @media screen and (max-width: 575px) {
            padding: rem(40px) rem(30px);
        }
       
        .image {
            min-width: rem(281px);
            max-height: rem(283px);

            img {
                width: 100%;
                max-height: 16rem;
                object-fit: contain;
            }
            @media screen and (max-width: 767px) {
                margin-bottom: rem(30px);
                text-align: center;
            }
        }
    }
}

.about-heading {
    font-weight: bold;
}

.OurExperts {
    .sectionTitle {
        font-size: rem(60px);
        @media screen and (max-width: 1600px) {
            font-size: rem(45px);
            margin-bottom: rem(16px);
        }
        @media screen and (max-width: 991px) {
            font-size: rem(40px);
        }
    }
    .image {
        min-width: rem(308px);
        max-height: rem(310px);

        img {
            width: 100%;
            max-height: rem(310px);
            object-fit: contain;
        }
        @media screen and (max-width: 767px) {
            margin-bottom: rem(30px);
            text-align: center;
        }
    }
}

.OurTestimonials {
    .sectionTitle {
        font-size: rem(60px);
        @media screen and (max-width: 1600px) {
            font-size: rem(45px);
            margin-bottom: rem(16px);
        }
        @media screen and (max-width: 991px) {
            font-size: rem(40px);
        }
    }
}

.abountUs-bg-color-gradient-1 {
    background: transparent linear-gradient(160deg, #D4FFF6 0%, #C1BCFF 100%) 0% 0%;
    position: relative;
    &:after {
        content: "";
        background: transparent linear-gradient(6deg, #D4FFF6 0%, #C1BCFF 100%) 0% 0%;
        top: -135px;
        left: 0;
        position: absolute;
        width: 100%;
        height: 135px;
        @media screen and (max-width: 991px) {
            height: 90px;
            top: -90px;
            background: transparent linear-gradient(8deg, #D4FFF6 0%, #C1BCFF 100%) 0% 0%;
        }
    }
}
.abountUs-bg-color-gradient-2 {
    background: transparent linear-gradient(46deg, #D4FFF6 0%, #C1BCFF 100%) 0% 0%;
}
.abountUs-bg-color-gradient-3 {
    background: transparent linear-gradient(150deg, #D4FFF6 0%, #C1BCFF 100%) 0% 0%;
}
.abountUs-bg-color-gradient-4 {
    background: transparent linear-gradient(28deg, #D4FFF6 0%, #C1BCFF 100%) 0% 0%;
}

.privacy-legal {
    padding-top: 115px;
    min-height: 55vh;
    .loader-privacy {
        display: flex;
        justify-content: center;
        height: 55vh;
        align-items: center;
    }
}