@import "_core/_assets/sass/utility/variables";

header {
    position: fixed;
    display: flex;
    align-items: center;
    width: 100%;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 840px;
    z-index: 99;
    padding: 8px 14px; 
    transition: .6s all ease-in-out;
    background: rgba(255, 255, 255, 0.38);
    border-radius: 16px;
    backdrop-filter: blur(30px);
    min-height: 70px;
    @media screen and (max-width: 1600px) {
        top: 35px;
    }
    @media screen and (max-width: 991px) {
        top: 10px;
    }
    @media screen and (max-width: 575px) {
        padding-inline: 8px;
    }
    .logo {
        svg {
            @media screen and (max-width: 575px) {
               max-width: 30px;
            }
        }
    }
    // stickycodee
    &.sticky {
        top: 10px;
        padding-block: 12px;
        @media screen and (max-width: 991px) {
            top: 0px;
        }
    }
    nav {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 5px;
        a {
            font-weight: 700;
            position: relative;
            transition: .3s all ease-in-out;
            font-size: rem(18px);
            
            @media screen and (max-width: 575px) {
                font-size: rem(15px);
                font-weight: 600;
            }
            @media screen and (max-width: 400px) {
                font-size: rem(14px);
            }
            &:not(:last-child) {
                margin-right: rem(34px);
                @media screen and (max-width: 1200px) {
                    margin-right: rem(25px);
                }
                @media screen and (max-width: 575px) {
                    margin-right: rem(10px);
                }
                // @media screen and (max-width: 400px) {
                //     margin-right: rem(15px);
                // }
            }
        }
    }
    .btn.btn-primary.sm {
        @media screen and (max-width: 575px) {
            padding: rem(12px) rem(12px);
        }
    }
}