@import "_core/_assets/sass/utility/variables";

.statsWrapper {
    border-radius: rem(32px);
    background-color: map-get($theme-colors, "white");
    padding: rem(24px) rem(38px) rem(20px);
    @media screen and (max-width: 1600px) {
        padding: rem(20px) rem(30px);
    }
    .statsListinig {
        margin-bottom: rem(15px);
        @media screen and (max-width: 991px) {
            margin-bottom: rem(20px);
        }
        h4 { 
            color: map-get($theme-colors, "primary");
            font-size: rem(52px);
            font-weight: 600;
            margin-bottom: 10px;
            @media screen and (max-width: 1600px) {
                font-size: rem(45px);
            }
            @media screen and (max-width: 1400px) {
                font-size: rem(42px);
            }
            @media screen and (max-width: 1200px) {
                font-size: rem(32px);
                margin-bottom: 6px;
            }
        }
        p {
            margin-bottom: 0;
            font-size: rem(20px);
            font-weight: 500;
            line-height: 1.4;
            @media screen and (max-width: 1600px) {
                font-size: rem(19px);
            }
            @media screen and (max-width: 1400px) {
                font-size: rem(18px);
            }
        }
    }
}