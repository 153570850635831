@import '../../../../_core/_assets/sass/import';
.mobNav{
    position: fixed;
    width: 100vw;
    left: -100%;
    background-color: white;
    border-top: 1px solid;
    z-index: 1;
    height: 100vh;
    padding: 30px;
    display: none;
    transition: all 0.5s ease;
    @media (max-width: 600px) {
        display: block;
    }
    &.active{
        left: 0;
    }
    .sidenav {
        list-style: none;

        .nav-item {
            padding: rem(18px);

            a {
                display: flex;
                align-items: center;
                gap: rem(17px);


                p {
                    font-weight: 500;
                    font-size: rem(18px);
                    color: map-get($theme-colors, "default");
                    margin-bottom: 0;
                    transition: all 0.15s;
                }
            }
            svg {
                width: rem(22px);
                height: rem(22px);
            }

            &.active {
                background-color: map-get($theme-colors, "primary");
                
                a {
                    p {
                        color: map-get($theme-colors, "white");
                    }
                }

                svg {
                    path {
                        fill: map-get($theme-colors, "white");
                    }
                }
            }
        }
    }
}